import React, { useEffect, useState } from 'react';

import { FilterRead } from '@headway/api/models/FilterRead';
import { SpecialtyRead } from '@headway/api/models/SpecialtyRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { SpecialtyApi } from '@headway/api/resources/SpecialtyApi';
import { Checkbox } from '@headway/helix/Checkbox';
import { CheckboxGroup } from '@headway/helix/CheckboxGroup';
import { FormControl } from '@headway/helix/FormControl';
import { getAvailableSpecialtiesForState } from '@headway/shared/utils/providerSearchFilters';
import {
  selectPatientVisibleSpecialties,
  sortByPatientDisplayName,
} from '@headway/shared/utils/specialties';

import { toaster } from './ToastManager';

type CheckboxGroupProps = React.ComponentProps<typeof CheckboxGroup>;

interface IssuesCheckListProps
  extends Omit<CheckboxGroupProps, 'children' | 'items'> {
  name: string;
  selectedState?: UnitedStates;
}

const IssuesCheckList = (props: IssuesCheckListProps) => {
  const [specialtyFilters, setSpecialtyFilters] = useState<SpecialtyRead[]>([]);
  useEffect(() => {
    const fetchIssueFilters = async () => {
      try {
        const res = await SpecialtyApi.getSpecialties({
          get_available_to_patients_only: true,
        });
        const patientVisibleSpecialties = sortByPatientDisplayName(
          selectPatientVisibleSpecialties(res)
        );
        const sortedSpecialties = sortByPatientDisplayName(
          patientVisibleSpecialties
        );
        const stateSpecialties = getAvailableSpecialtiesForState(
          sortedSpecialties,
          props.selectedState
        );
        setSpecialtyFilters(stateSpecialties);
      } catch (err: AnyTS4TryCatchUnknownError) {
        toaster.error(`Could not load all form data. Please refresh.`);
      }
    };
    fetchIssueFilters();
  }, []);

  return (
    <div
      css={{
        '& .hlx-chip-root': {
          display: 'none',
        },
      }}
    >
      <FormControl component={CheckboxGroup} {...props}>
        {specialtyFilters
          .filter((filter) => !!filter.key && !!filter.patientDisplayName)
          .map((filter) => {
            return (
              <Checkbox key={filter.key} value={filter.key}>
                {filter.patientDisplayName}
              </Checkbox>
            );
          })}
      </FormControl>
    </div>
  );
};

export { IssuesCheckList };
