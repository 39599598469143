import { inject } from 'mobx-react';
import React from 'react';

import { Button } from '@headway/helix/Button';
import { FlagsConsumer } from '@headway/shared/FeatureFlags/react';
import { trackEvent } from '@headway/shared/utils/analytics';
import { ReferProvider } from '@headway/ui/providers/ReferProvider';
import { ReferralProgram } from '@headway/ui/providers/ReferralProgram';

const ReferralAccountComponent = () => {
  return (
    <>
      <div className="flex flex-col space-y-2 pt-4">
        <Button
          type="button"
          variant="primary"
          size="large"
          onPress={() => {
            // AuthStore.openSignupModal();
            trackEvent({
              name: 'Join as a Provider Button Clicked',
            });
          }}
        >
          Create an account to refer a provider
        </Button>
        <Button
          type="button"
          variant="secondary"
          size="large"
          // onPress={AuthStore.openLoginModal}
          data-testid="login-header-button"
        >
          Or sign in
        </Button>
      </div>
    </>
  );
};

interface ReferAProviderProps {
  AuthStore?: any;
}

export const ReferAProvider: React.FC<
  React.PropsWithChildren<ReferAProviderProps>
> = inject('AuthStore')(({ AuthStore }) => {
  return (
    <>
      <FlagsConsumer flagRequests={{ extolePhase2ReferralProgram: false }}>
        {({ extolePhase2ReferralProgram }) =>
          extolePhase2ReferralProgram ? (
            <ReferralProgram
              AuthStore={AuthStore}
              ReferralAccountComponent={ReferralAccountComponent}
            />
          ) : (
            <ReferProvider AuthStore={AuthStore} />
          )
        }
      </FlagsConsumer>
    </>
  );
});
