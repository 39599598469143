import React from 'react';

import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';

type MessageFormatter = (args?: string[]) => string | React.ReactNode;

const MESSAGE_MAP: { [key in CodingEnforcementErrorType]: MessageFormatter } = {
  [CodingEnforcementErrorType.CONCURRENT_CODE]: () =>
    'The codes/services are considered to be concurrent by insurance companies, ' +
    'and will be denied or rejected. Please choose the most applicable code.',

  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITH_E_AND_M]: () =>
    "If you've provided psychotherapy services to your client in addition to E&M, " +
    'use add-on codes 90833, 90836, or 90838 to bill the minutes of talk therapy provided.',

  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITH_CRISIS]: () =>
    'Crisis codes cannot be billed on the same day as another psychotherapy service. These ' +
    'services are considered concurrent by the insurance company and will be denied or rejected by the payer.' +
    ' Please choose the most applicable code.',

  [CodingEnforcementErrorType.DUPLICATE_CONFIRM_SAME_DAY]: () =>
    'Duplicate sessions: Can only bill multiple sessions on one day when billing both group ' +
    'and individual psychotherapy.',
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_6_MONTHS]:
    () => (
      <>
        90791 is a Diagnostic Evaluation Service Code, and is only expected to
        be used once per client in a 6 month period. If you need to use these
        codes more than once during this timeframe, please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA]:
    () => (
      <>
        90791 is a Diagnostic Evaluation Service Code, and is only expected to
        be used once per Anthem CA client in a 12 month period. If you need to
        use these codes more than once during this timeframe, please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21]:
    () => (
      <>
        90791 is a Diagnostic Evaluation Service Code, and is only expected to
        be used at most twice in a 12 month period for Anthem CA clients under
        the age of 21. If you need to use these codes more than once during this
        timeframe, please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_6_MONTHS]:
    () => (
      <>
        90792 is a Diagnostic Evaluation Service Code, and is only expected to
        be used once per client in a 6 month period. If you need to use these
        codes more than once during this timeframe, please{' '}
        <a
          // TODO: code in /packages should not reference app level env vars.
          href={`${process.env.REACT_APP_MAIN_URL}/contact`}
          target="_blank"
          rel="noopener noreferrer"
        >
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA]:
    () => (
      <>
        90792 is a Diagnostic Evaluation Service Code, and is only expected to
        be used once per Anthem CA client in a 12 month period. If you need to
        use these codes more than once during this timeframe, please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21]:
    () => (
      <>
        90792 is a Diagnostic Evaluation Service Code, and is only expected to
        be used at most twice in a 12 month period for Anthem CA clients under
        the age of 21. If you need to use these codes more than once during this
        timeframe, please{' '}
        <a href="/contact" target="_blank" rel="noopener noreferrer">
          contact us
        </a>{' '}
        to authorize the service.
      </>
    ),

  [CodingEnforcementErrorType.E_AND_M_NEW_PATIENTS_ONLY]: () =>
    'This code should only be used for clients who are new to your practice, or who have not had a' +
    ' session billed with you in the last three years. You can use 99213, 99214, or 99215 to capture' +
    ' E&M services with existing clients.',
  [CodingEnforcementErrorType.INTERACTIVE_COMPLEXITY_WITH_OTHER_CODES]: () =>
    'Interactive Complexity (90785) can only be billed alongside psychotherapy codes ' +
    '90832, +90833, 90834, +90836, 90837, +90838, 90853 or with 90791/90792. Any other use will be denied.',

  [CodingEnforcementErrorType.ZCODE_WITHOUT_FCODE]: () =>
    "Some insurance plans won't cover Z-codes billed as the primary diagnosis",

  [CodingEnforcementErrorType.FCODE_FIRST_REQUIRED]: () =>
    'This code cannot be used as a primary diagnosis. Please select an F code to be used as the primary diagnosis.',

  [CodingEnforcementErrorType.RCODE_WITHOUT_FCODE]: () =>
    '“R” codes in the ICD-10 represent "signs and symptoms" only. Health plans require that an R code is ' +
    'accompanied by an accepted behavioral health diagnosis code. Please select a behavioral code and resubmit.',

  [CodingEnforcementErrorType.MULTIPLE_F32_CODES]: () =>
    'You can only select one code from F32.- per session.',

  [CodingEnforcementErrorType.MULTIPLE_F33_CODES]: () =>
    'You can only select one code from F33.- per session.',

  [CodingEnforcementErrorType.CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS]: (
    args
  ) => {
    if (!args || args.length === 0) {
      throw Error('Cannot format error message');
    }
    let codes;
    if (args.length === 1) {
      codes = args[0];
    } else if (args.length === 2) {
      codes = `${args.join(' and ')}`;
    } else {
      codes = `${args.slice(0, -1).join(', ')}, and ${args[args.length - 1]}`;
    }

    return `Some insurance plans won't cover CPT ${
      args.length === 1 ? 'code' : 'codes'
    } ${codes}`;
  },
  [CodingEnforcementErrorType.HALLUCINATION_OUTSIDE_INTAKE]: () =>
    'Only permitted on intake appointments (90791, 90792, 99202, 99203, 99204, 99205).',

  [CodingEnforcementErrorType.OPTUM_EAP_DISALLOWED_CODE]: () =>
    'Optum EAP allows billing for 90832, 90834, 90846, 90847, and 90853 only.',

  [CodingEnforcementErrorType.CIGNA_EAP_DISALLOWED_CODE]: () =>
    'Cigna EAP allows billing for 99404 only.',

  [CodingEnforcementErrorType.ANTHEM_EAP_DISALLOWED_CODE]: () =>
    'Anthem EAP allows billing for 99404 only.',

  [CodingEnforcementErrorType.ANTHEM_CA_DISALLOWED_CODE]: () => (
    <>
      Anthem Blue Cross California restricts billing for the following codes:
      <ul>
        <li>
          Neuropsychological test codes 96132, 96133, 96138, 96139 and 96146 are
          not allowed
        </li>
        <li>
          Codes 96136 and 96137 may be used, but only by providers licensed as
          clinical psychologists
        </li>
      </ul>
    </>
  ),

  [CodingEnforcementErrorType.NO_INSURANCE_ON_FILE]: () =>
    'Patient does not have insurance on file. Please add their insurance before confirming session details.',

  [CodingEnforcementErrorType.NO_EAP_ON_FILE]: () =>
    'Patient does not have an EAP authorization on file. Please add their EAP before confirming session details.',

  [CodingEnforcementErrorType.MULTIPLE_BASE_CODES]: () =>
    'Only one base CPT code is allowed.',

  [CodingEnforcementErrorType.MULTIPLE_UNIQUE_ADD_ONS]: () =>
    'Only one base CPT code and one kind of add-on CPT code is allowed.',

  [CodingEnforcementErrorType.NO_CPT_CODES]: () =>
    'At least one CPT code is required.',
  [CodingEnforcementErrorType.NO_DIAGNOSIS_CODES]: () =>
    'At least one diagnosis code is required.',
  [CodingEnforcementErrorType.MANIC_WITH_BIPOLAR]: () =>
    "Manic episodes (F30.-) can't be coded with bipolar disorders (F31.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.MANIC_WITH_DEPRESSIVE]: () =>
    "Manic episodes (F30.-) can't be coded with Major Depressive Disorder, Single Episode (F32.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.MANIC_WITH_RECURRENT]: () =>
    "Manic episodes (F30.-) can't be coded with Major Depressive Disorder, Recurrent Episode (F33.-) because of Excludes1 rules. Please choose the most relevant code.",

  [CodingEnforcementErrorType.BIPOLAR_WITH_MANIC]: () =>
    "Bipolar disorders (F31.-) can't be coded with manic episodes (F30.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.BIPOLAR_WITH_DEPRESSIVE]: () =>
    "Bipolar disorders (F31.-) can't be coded with Major Depressive Disorder, Single Episode (F32.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.BIPOLAR_WITH_RECURRENT]: () =>
    "Bipolar disorders (F31.-) can't be coded with Major Depressive Disorder, Recurrent Episode (F33.-) because of Excludes1 rules. Please choose the most relevant code.",

  [CodingEnforcementErrorType.DEPRESSIVE_WITH_MANIC]: () =>
    "Major Depressive Disorder, Single Episode (F32.-) can't be coded with manic episodes (F30.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.DEPRESSIVE_WITH_BIPOLAR]: () =>
    "Major Depressive Disorder, Single Episode (F32.-) can't be coded with bipolar disorders (F31.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.DEPRESSIVE_WITH_RECURRENT]: () =>
    "Major Depressive Disorder, Single Episode (F32.-) can't be coded with Major Depressive Disorder, Recurrent Episode (F33.-) because of Excludes1 rules. Please choose the most relevant code.",

  [CodingEnforcementErrorType.RECURRENT_WITH_MANIC]: () =>
    "Major Depressive Disorder, Recurrent Episode (F33.-) can't be coded with manic episodes (F30.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.RECURRENT_WITH_BIPOLAR]: () =>
    "Major Depressive Disorder, Recurrent Episode (F33.-) can't be coded with bipolar disorders (F31.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.RECURRENT_WITH_DEPRESSIVE]: () =>
    "Major Depressive Disorder, Recurrent Episode (F33.-) can't be coded with Major Depressive Disorder, Single Episode (F32.-) because of Excludes1 rules. Please choose the most relevant code.",

  [CodingEnforcementErrorType.EATING_DISORDER_WITH_ANOREXIA]: () =>
    "Eating disorders (F50.-) can't be coded with Anorexia NOS (R63.0) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.EATING_DISORDER_WITH_POLYPHAGIA]: () =>
    "Eating disorders (F50.-) can't be coded with Polyphagia (R63.2) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.EATING_DISORDER_WITH_FEEDING_PROBLEMS_OF_NEWBORN]:
    () =>
      "Eating disorders (F50.-) can't be coded with Feeding problems of Newborn (P92.-) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITHOUT_E_AND_M]: () =>
    '90833, 90836, 90838 can only be billed with an E&M code. If you are providing psychotherapy on its own, you can use 90832, 90834, or 90837.',
  [CodingEnforcementErrorType.PSYCHOTHERAPY_FOR_CRISIS_BY_ITSELF]: () =>
    '90840 can only be billed with 90839. Psychotherapy for crisis of less than 30 minutes should be reported with 90832 or 90833 (when provided with an E&M service). You can use code 90839 to report the first 30-74 minutes of psychotherapy for a crisis on a given date.',
  [CodingEnforcementErrorType.R46_81_EXCLUDES_F42]: () =>
    'R46.81 cannot be billed with obsessive-compulsive disorder (F42.-) under excludes.',
  [CodingEnforcementErrorType.E66_09_EXCLUDES_DX]: () =>
    'Cannot be billed w/ E23.6, E88.2, Q87.11, Use additional code to identify BMI, if known (Z68.-).',
  [CodingEnforcementErrorType.G93_31_EXCLUDES_DX]: () =>
    'Cannot be billed with R53.82 or F48.8',
  [CodingEnforcementErrorType.G47_9_CANNOT_BE_PRIMARY]: () =>
    'G47.9 cannot be billed as the primary diagnosis.',
  [CodingEnforcementErrorType.BMI_WITHOUT_OBESITY]: () =>
    'Must be listed AFTER E66.- (Obesity) codes > tertiary place. (eg. F code should be primary, Obesity code secondary, BMI tertiary).',
  [CodingEnforcementErrorType.Z56_9_WITH_PRIMARY_F_CODE]: () =>
    'Please choose a Primary Diagnosis code first. Z56.9 is a “Factors influencing health status and contact with health services” code and must follow a primary diagnosis code for a Mental, Behavioral or Neuro developmental Disorder (F10-F99).',
  [CodingEnforcementErrorType.Z60_0_WITH_PRIMARY_F_CODE]: () =>
    'Please choose a Primary Diagnosis code first. Z60.0 is a “Factors influencing health status and contact with health services” code and must follow a primary diagnosis code for a Mental, Behavioral or Neuro developmental Disorder (F10-F99).',
  [CodingEnforcementErrorType.Z63_0_WITH_PRIMARY_F_CODE]: () =>
    'Please choose a Primary Diagnosis code first. Z63.0 is a “Factors influencing health status and contact with health services” code and must follow a primary diagnosis code for a Mental, Behavioral or Neuro developmental Disorder (F10-F99).',
  [CodingEnforcementErrorType.Z63_0_WITH_Z69_1]: () =>
    "Problems in relationship with spouse or partner can't be coded with counseling for spousal or partner abuse problems (Z69.1) because of Excludes 1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.Z63_0_WITH_Z70_]: () =>
    "Problems in relationship with spouse or partner can't be coded with counseling related to sexual attitude, behavior, and orientation (Z70.-) because of Excludes 1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.Z63_4_WITH_PRIMARY_F_CODE]: () =>
    'Please choose a Primary Diagnosis code first. Z63.4 is a “Factors influencing health status and contact with health services” code and  must follow a primary diagnosis code for a Mental, Behavioral or Neuro developmental Disorder (F10-F99).',
  [CodingEnforcementErrorType.F84_0_WITH_F84_5]: () =>
    "Autistic Disorder (F84.0) can't be coded with Asperger's Syndrome (F84.5) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F53_0_WITH_O90_6]: () =>
    "Postpartum depression (F53.0) can't be coded with Postpartum dysphoria (O90.6) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F64_1_WITH_F64_2]: () =>
    "Dual role transvestism (F64.1) can't be coded with Gender identity disorder in childhood (F64.2) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F64_2_WITH_F64_0]: () =>
    'Gender identity disorder in childhood (F64.2) cannot be coded with Gender identity disorder in adolescence and adulthood (F64.0) because of Excludes1 rules. Please choose the most relevant code.',
  [CodingEnforcementErrorType.ANOREXIA_NERVOSA_WITH_PSYCHOGENIC_LOSS_OF_APPETITE]:
    () =>
      "Anorexia Nervosa (F50.0-) can't be coded with psychogenic loss of appetite (F50.89) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F33_8_WITH_F30_]: () =>
    "Major Depressive Disorder, Recurrent Episode (F33.0 - F33.9) includes Other Recurrent Depressive Disorders (F33.8) and can't be coded with Manic Episode (F30.-) because of  Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F33_8_WITH_F31_]: () =>
    "Major Depressive Disorder, Recurrent Episode (F33.0 - F33.9) includes Other Recurrent Depressive Disorders (F33.8) and can't be coded with Bipolar disorders (F31.-) because of  Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.F43_8_IS_UNBILLABLE]: () =>
    'F43.8 is no longer a billable diagnosis code, but you can use F43.81 or F43.89 for specificity. Please choose whichever code is most relevant.',
  [CodingEnforcementErrorType.R45_2_DISALLOWED]: () =>
    'R45.2 can only be coded with 90791, 90792, or 99202-99205. Please choose the most relevant code.',
  [CodingEnforcementErrorType.PHYSIOLOGICAL_CODE_REQUIRED]: (args) => {
    if (!args || args.length === 0) {
      throw Error('Cannot format error message');
    }
    let codeDescription = args[0];
    let article = 'a';
    if (/[aeiou]/i.test(codeDescription[0])) {
      article = 'an';
    }
    return `Please code the underlying physiological condition before ${article} ${codeDescription} code.  Codes will be automatically reordered to comply with “Code First” rules.`;
  },
  [CodingEnforcementErrorType.PANIC_WITH_AGORAPHOBIA]: () =>
    "Panic disorder [episodic paroxysmal anxiety] (F41.0) can't be coded with Panic Disorder with Agoraphobia (F40.01) because of Excludes1 rules. Please choose the most relevant code.",
  [CodingEnforcementErrorType.CPT_90834_DURATION_MISMATCH]: () =>
    'Session must be between 38-52 minutes for 90834',
  [CodingEnforcementErrorType.CPT_90837_DURATION_MISMATCH]: () =>
    'Session must be 53+ minutes for 90837.',
  [CodingEnforcementErrorType.CPT_90833_DURATION_MISMATCH]: () =>
    'This code requires a session to be a minimum of 16 minutes long.',
  [CodingEnforcementErrorType.CPT_90833_PSYCHOTHERAPY_DURATION_MISMATCH]: () =>
    'This code requires psychotherapy duration to be a minimum of 16 minutes long.',
  [CodingEnforcementErrorType.CPT_90836_DURATION_MISMATCH]: () =>
    'This code requires a session to be a minimum of 38 minutes long.',
  [CodingEnforcementErrorType.CPT_90836_PSYCHOTHERAPY_DURATION_MISMATCH]: () =>
    'This code requires psychotherapy duration to be a minimum of 38 minutes long.',
  [CodingEnforcementErrorType.CPT_90838_DURATION_MISMATCH]: () =>
    'This code requires a session to be a minimum of 53 minutes long.',
  [CodingEnforcementErrorType.CPT_90838_PSYCHOTHERAPY_DURATION_MISMATCH]: () =>
    'This code requires psychotherapy duration to be a minimum of 53 minutes long.',
  [CodingEnforcementErrorType.CPT_90833_90836_90838_MISSING_OR_UNREASONABLE_PSYCHOTHERAPY_TIMES]:
    () =>
      `90833, 90836, and 90838 require that both the psychotherapy start and end times, as well as the exact start and end times of the appointment, be specified. The psychotherapy times must fall strictly within the appointment's exact start and end times.`,
  [CodingEnforcementErrorType.OVERLAPPING_APPOINTMENT]: () =>
    'You can only confirm sessions at the same time if all overlapping sessions are coded as group therapy (90853).',
  [CodingEnforcementErrorType.MULTIPLE_E_AND_M]: () =>
    'Insurers will reject claims that bill two E&M CPT codes (99212-99215, 99202-99205) on the same day for the same client, as this represents a duplicated service. Please select the most applicable E&M code for your session with this client.',
  [CodingEnforcementErrorType.CRISIS_CODE_WITH_E_AND_M]: () => (
    <>
      Crisis therapy (CPT code 90839) cannot be billed as an add-on to an
      Evaluation and Management Service. If the client's situation warrants
      crisis intervention and you provided psychotherapy:
      <ul>
        <li>
          Ensure your documentation denotes the delivery of crisis intervention
        </li>
        <li>
          Select your Evaluation and Management Code according to the{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            appropriate Medical Decision Making level
          </a>
        </li>
        <li>
          Select the appropriate psychotherapy add-on code according to the
          documented time spent providing psychotherapy to the client:
          <ul>
            <li>90833 - 16-37 minutes of psychotherapy</li>
            <li>90836 - 38-52 minutes of psychotherapy</li>
            <li>90838 - 53+ minutes of psychotherapy</li>
          </ul>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.CRISIS_CODE_WITH_E_AND_M_CRISIS]: () => (
    <>
      <p>
        Crisis therapy (CPT code 90839) cannot be billed as an add-on to an
        Evaluation and Management Service. E&M codes 99215 or 99205 denote a{' '}
        <a
          href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
          target="_blank"
          rel="noopener noreferrer"
        >
          High Medical Decision Making Level
        </a>
        , which is inclusive of crisis intervention.
      </p>
      <p>
        Please select the applicable psychotherapy add-on code according to the
        documented time spent providing psychotherapy to the client:
      </p>
      <ul>
        <li>90833 - 16-37 minutes of psychotherapy</li>
        <li>90836 - 38-52 minutes of psychotherapy</li>
        <li>90838 - 53+ minutes of psychotherapy</li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PSYCH_ADDON_WITH_E_AND_M]: () => (
    <>
      Evaluation and Management (E/M) codes must be entered first, followed by
      the psychotherapy add-on code. Only one psychotherapy add-on code can be
      used per session.
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99213_90838_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “Low” level (justifies evaluation and management code
          99213)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90838)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99204_90838]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “Moderate” level (justifies evaluation and management
          code 99204)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90838)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99204_90838_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “Moderate” level (justifies evaluation and management
          code 99204)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90838)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99214_90838]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “Moderate” level (justifies evaluation and management
          code 99214)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90838)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99214_90838_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “Moderate” level (justifies evaluation and management
          code 99214)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90833)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205]: () => (
    <>
      When using this CPT code, one of the following criteria must be met and
      documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>Met the minimum time requirement of 60 minutes for the session.</li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90833]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided between 16-37 minutes of psychotherapy (justifies add on code
          90833)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90836]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided between 38-52 minutes of psychotherapy (justifies add on code
          90833)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90838]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided between 53+ minutes of psychotherapy (justifies add on code
          90833)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90833_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided between 16-37 minutes of psychotherapy (justifies add on code
          90833)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90836_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided between 38-52 minutes of psychotherapy (justifies add on code
          90836)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90838_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99205)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90833)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215]: () => (
    <>
      When using this code, one of following criteria must be met and documented
      in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>Met the minimum time requirement of 40 minutes for the session.</li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90833]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>
          Provided between 16-37 minutes of psychotherapy (justifies add on code
          90833)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90836]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>
          Provided between 16-37 minutes of psychotherapy (justifies add on code
          90836)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90838]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215) Provided 53+ minutes of psychotherapy (justifies add on
          code 90838)
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90833_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>
          Provided between 16-37 minutes of psychotherapy (justifies add on code
          90833)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90836_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>
          Provided between 38-52 minutes of psychotherapy (justifies add on code
          90836)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90838_90785]: () => (
    <>
      When using this combination of CPT codes, the following criteria must be
      met and documented in your notes for the claim to be approved by insurers:
      <ul>
        <li>
          Met at least{' '}
          <a
            href="https://help.headway.co/hc/en-us/articles/14870152109076-Evaluation-and-Management-E-M-codes-with-add-ons#h_01HETT3J9W9NWDYT58V9EHNFKY"
            target="_blank"
            rel="noopener noreferrer"
          >
            two elements of Medical Decision Making
          </a>{' '}
          that reached the “High” level (justifies evaluation and management
          code 99215)
        </li>
        <li>
          Provided 53+ minutes of psychotherapy (justifies add on code 90838)
        </li>
        <li>
          <a
            href="https://help.headway.co/hc/en-us/articles/360059490751-CPT-and-diagnosis-codes#h_01FYWC3CK8GRY31YD7FJDMDAXN"
            target="_blank"
            rel="noopener noreferrer"
          >
            Met “interactive complexity” elements (justifies code 90785)
          </a>
        </li>
      </ul>
    </>
  ),
  [CodingEnforcementErrorType.SESSION_LIMIT]: (args) => {
    if (!args || args.length === 0) {
      throw Error('Cannot format error message');
    }

    const is_prescriber = args[0];
    const session_limit = args[1];
    const provider_name = args[2];

    return (
      <>
        <p>
          You've confirmed {session_limit}{' '}
          {is_prescriber ? 'appointments' : 'sessions'} for this date.
        </p>
        <p>
          Because this number is higher than usual for a single day of service,
          please double check that:
        </p>
        <ul>
          <li>
            {provider_name} fully provided care for all of these{' '}
            {is_prescriber ? 'appointments' : 'sessions'}, rather than any other
            individuals, such as{' '}
            {is_prescriber
              ? 'students, interns or residents'
              : 'students or interns'}
            . Note that Headway does not currently allow{' '}
            <a
              href="https://help.headway.co/hc/en-us/articles/14791974145172-Incident-to-billing-"
              target="_blank"
              rel="noopener noreferrer"
            >
              incident-to billing
            </a>
            .
          </li>
          <li>
            All appointments took place on this date of service, in accordance
            with Headway's{' '}
            <a
              href="https://headway.co/legal/policies?policy=client-charging"
              target="_blank"
              rel="noopener noreferrer"
            >
              Charging for Appointments Policy
            </a>
            .
          </li>
        </ul>
      </>
    );
  },
  [CodingEnforcementErrorType.E_AND_M_PSYCH_ADDON_SECOND_ONLY]: () =>
    'Only the following psychotherapy add-on codes may be used with E/M codes: 90833, 90836 or 90838. ',
  [CodingEnforcementErrorType.E_AND_M_WITH_INTERACTIVE_COMPLEXITY_THIRD]: () =>
    'Evaluation and Management (E/M) codes must be entered first, followed by the psychotherapy add-on code. The interactive complexity code (90875) comes third, and may only be used if the other two codes are present, and at most once per session',
  [CodingEnforcementErrorType.E_AND_M_WITH_THIRD_WARNING]: () =>
    '90785 can only be used in the third position after an Evaluation and Management (E/M) code and psychotherapy add-on code.',
};

export function formatCodingEnforcementMessage(
  errorType: CodingEnforcementErrorType,
  args?: string[]
) {
  return MESSAGE_MAP[errorType]?.(args) ?? undefined;
}
