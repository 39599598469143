import React, { useMemo, useState } from 'react';

import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';
import { ProviderQuestionnaireReadV2 } from '@headway/api/models/ProviderQuestionnaireReadV2';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { Button } from '@headway/helix/Button';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';

import { PFECCheckboxTree } from 'components/PFECCheckboxTree';
import { useQuestionnaireContext } from 'views/IntakeQuestionnaireV2/QuestionnaireV2Context';

type PanelableCarriersSelectionButtonAndModalProps = {
  provider: ProviderRead;
  providerQuestionnaire: ProviderQuestionnaireReadV2;
  panelabilityEvaluation: ProviderPanelabilityEvaluation;
};

export const PanelableCarriersSelectionButtonAndModal = ({
  provider,
  providerQuestionnaire,
  panelabilityEvaluation,
}: PanelableCarriersSelectionButtonAndModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const panelableAndMaybePanelablePFECs = useMemo(() => {
    return panelabilityEvaluation.stateInsuranceCarrierPanelabilityEvaluations
      .filter(
        (evaluation) =>
          evaluation.finalPanelabilityStatus === PanelabilityStatus.PANELABLE ||
          evaluation.finalPanelabilityStatus ===
            PanelabilityStatus.MAYBE_PANELABLE
      )
      .map(
        (evaluation) =>
          provider.providerFrontEndCarriers?.find(
            (pfec) =>
              pfec.frontEndCarrierId === evaluation.frontEndCarrierId &&
              pfec.providerLicenseState.state === evaluation.state
          )
      )
      .filter((pfec): pfec is NonNullable<typeof pfec> => !!pfec);
  }, [provider, panelabilityEvaluation]);

  const [deselectedPfecIds, setDeselectedPfecIds] = useState(
    providerQuestionnaire.rawData?.deselectedPfecIds || []
  );
  const selectedPFECs = panelableAndMaybePanelablePFECs
    .map((pfec) => pfec.id)
    .filter((id) => !deselectedPfecIds.includes(id));
  const setSelectedPFECs = (newSelectedPFECs: number[]) => {
    const newDeselectedPFECs = panelableAndMaybePanelablePFECs
      .map((pfec) => pfec.id)
      .filter((id) => !newSelectedPFECs.includes(id));
    setDeselectedPfecIds(newDeselectedPFECs);
  };

  const { updateQuestionnaireWithoutAdvancingCurrentStep } =
    useQuestionnaireContext();
  const onSave = async () => {
    await updateQuestionnaireWithoutAdvancingCurrentStep({ deselectedPfecIds });
    setIsOpen(false);
  };

  return (
    <>
      <Button
        size="medium"
        onPress={() => {
          setIsOpen(true);
        }}
      >
        Edit your plans
      </Button>
      <Modal
        title="Edit insurance plans"
        isOpen={isOpen}
        onDismiss={() => {
          setIsOpen(false);
        }}
      >
        <ModalContent>
          <PFECCheckboxTree
            ariaLabel={'Insurance Plan Selection'}
            provider={provider}
            selectedPFECs={selectedPFECs}
            onSelectedPFECsChange={(newSelectedPFECs) =>
              setSelectedPFECs(newSelectedPFECs)
            }
            providerFrontEndCarriers={panelableAndMaybePanelablePFECs}
            separateVirtualNetworks
          />
        </ModalContent>
        <ModalFooter>
          <Button variant="primary" onPress={onSave}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
