import { CheckCircle } from '@mui/icons-material';
import { Field, FieldProps, Formik, FormikHelpers } from 'formik';
import { useProvider } from 'hooks';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { ErrorCode } from '@headway/api/models/ErrorCode';
import { FrontEndCarrierNested } from '@headway/api/models/FrontEndCarrierNested';
import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { LookupSource } from '@headway/api/models/LookupSource';
import { LookupStatus } from '@headway/api/models/LookupStatus';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientUpdate } from '@headway/api/models/ProviderPatientUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { EligibilityLookupApi } from '@headway/api/resources/EligibilityLookupApi';
import { ProviderApi } from '@headway/api/resources/ProviderApi';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { UserApi } from '@headway/api/resources/UserApi';
import { UserInsuranceApi } from '@headway/api/resources/UserInsuranceApi';
import { AddInsuranceHumanInputErrorSurfacedEvent } from '@headway/avo';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { Checkbox } from '@headway/helix/Checkbox';
import { CurrencyField } from '@headway/helix/CurrencyField';
import { DateField } from '@headway/helix/DateField';
import { FormControl } from '@headway/helix/FormControl';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ListHeader } from '@headway/helix/ListHeader';
import { Modal, ModalContent, ModalFooter } from '@headway/helix/Modal';
import { PageSection } from '@headway/helix/Page';
import { SectionHeader } from '@headway/helix/SectionHeader';
import { Item, Select } from '@headway/helix/Select';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { CARRIERS_REQUIRING_AUTHORIZATION } from '@headway/shared/constants/carrierIds';
import {
  ENABLE_NO_TELEHEALTH_EXPERIENCE,
  NEW_HUMAN_INPUT_ERROR_PRODUCT_EXPERIENCE,
  NEW_MEMBER_ID_VALIDATION_EXPERIENCE,
  TELEHEALTH_LOCATIONS_SIGMUND,
  USE_BENEFITS_MAX_FOR_CLAIM_READINESS,
} from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { trackEvent } from '@headway/shared/utils/analytics';
import {
  getCarrierHelperText,
  getCarrierRegex,
  isFrontEndCarrierIdBcbs,
  isFuzzyMatchedLookup,
} from '@headway/shared/utils/insuranceUtils';
import {
  categorizeLookupError,
  getErrorMessageFromCode,
  getErrorMessageFromString,
  getGenericLookupErrorMessage,
  LookupErrorCategory,
  shouldShowLookupErrors,
} from '@headway/shared/utils/lookupErrors';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import { FormRow } from '@headway/ui/form';
import { SafeFormikForm } from '@headway/ui/form/SafeFormikForm';
import { LogoLoader } from '@headway/ui/LogoLoader';
import { MemberIdValidationGuidance } from '@headway/ui/MemberIdValidationGuidance';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { notifyError, notifyWarning } from '@headway/ui/utils/notify';

import { BigRadio } from 'components/BigRadio/BigRadio';
import { BigRadioGroup } from 'components/BigRadio/BigRadioGroup';
import { TAX_ID_SSN_MATCH_ERROR } from 'components/SelfPayOptInForm/SelfPayOptInForm';
import { PROVIDER_SELECTABLE_BILLING_TYPES } from 'constants/providerSelectableBillingTypes';
import { useAuthStore } from 'stores/AuthStore';
import {
  getAuthorizationInstructionsModalTitle,
  InsuranceAuthorizationInstructionsModalContent,
} from 'views/Patients/InsuranceAuthorizationInstructionsModalContent';
import { InsuranceNeedsAuthzGuidanceCard } from 'views/Patients/InsuranceNeedsAuthzGuidanceCard';

import { useInsuranceStatus } from '../../../hooks/useInsuranceStatus';
import { patientBillingTypeFormValidationSchema } from '../PatientBillingTypeForm';
import { PatientBillingUnconfirmedWarning } from '../PatientBillingUnconfirmedWarning';
import { formatFirstName } from '../utils/addPatientModalUtils';
import {
  MAX_SELF_PAY_RATE,
  MAX_SELF_PAY_RATE_MESSAGE,
  MIN_NONZERO_SELF_PAY_RATE,
  MIN_NONZERO_SELF_PAY_RATE_MESSAGE,
} from '../utils/billingType';
import { useProviderEventsUnconfirmedQuery } from '../utils/customQueries';
import { hasNoRemainingSessions } from '../utils/hasNoRemainingSessions';
import {
  AddPatientModalContext,
  AddPatientModalPage,
} from './AddPatientModalContext';
import { EnableSelfPayForm } from './EnableSelfPayForm';

export type AddBillingInformationFormValues = {
  billingTypeDefault: BillingType;
  firstName: string;
  lastName: string;
  dob: string;
  insuranceCarrierId?: number;
  memberId: string;
  groupNumber: string;
  selfPayRateDefault?: number;
  confirmComms?: boolean;
  confirmNotInNetwork?: boolean;
  acceptedTerms?: boolean;
  taxId?: string;
};

interface InsuranceFormUpdate {
  userRead: UserRead;
  billingType: BillingType;
  userInsurance?: UserInsuranceRead;
  eligibilityLookup?: EligibilityLookupRead;
}

export interface AddBillingInformationFormProps {
  client: UserRead;
  providerPatient: ProviderPatientRead;
  onBillingAdded: (updatedUser: UserRead) => void;
}

let prevFirstNameSpecialCharacters: string | null = null;
let prevLastNameSpecialCharacters: string | null = null;
const clientBillingTypeFormValidation = ({
  provider,
  client,
}: {
  provider: ProviderRead;
  client: UserRead;
}) =>
  Yup.object().shape({
    newHumanInputExperienceEnabled: Yup.boolean(),
    billingTypeDefault: Yup.mixed<BillingType>()
      .oneOf(
        Object.values(BillingType).filter((b) =>
          PROVIDER_SELECTABLE_BILLING_TYPES.includes(b)
        )
      )
      .required('Billing type is required'),
    firstName: Yup.string()
      .required('First name is required')
      .when('newHumanInputExperienceEnabled', {
        is: true,
        then: (schema: Yup.StringSchema<string>) =>
          schema.test(
            'no-special-characters',
            "Don't include any special characters or numbers, write exactly what's printed on the insurance card.",
            (value: string | undefined | null) => {
              const specialCharacters = (value || '').match(/[^a-zA-Z'. -]/g);
              if (!!specialCharacters) {
                // only track the event when the special characters change
                if (
                  prevFirstNameSpecialCharacters !== specialCharacters.join('')
                ) {
                  prevFirstNameSpecialCharacters = specialCharacters.join('');
                  console.log(
                    'prevFirstNameSpecialCharacters',
                    prevFirstNameSpecialCharacters
                  );
                  trackEvent({
                    name: 'Add Insurance Inline Error Surfaced',
                    properties: {
                      patientUserId: client.id,
                      providerId: provider.id,
                      insuranceElement: 'first_name',
                      insuranceElementErrorDescription: 'invalid_character',
                      insuranceElementErrorString: specialCharacters,
                      insuranceSubmissionBlocked: true,
                    },
                  });
                }
                return false; // Validation failed
              }
              prevFirstNameSpecialCharacters = null;
              return true; // Validation passed
            }
          ),
      }),
    lastName: Yup.string()
      .required('Last name is required')
      .when('newHumanInputExperienceEnabled', {
        is: true,
        then: (schema: Yup.StringSchema<string>) =>
          schema.test(
            'no-special-characters',
            "Don't include any special characters or numbers, write exactly what's printed on the insurance card.",
            (value: string | undefined | null) => {
              const specialCharacters = (value || '').match(/[^a-zA-Z'. -]/g);
              if (!!specialCharacters) {
                // only track the event when the special characters change
                if (
                  prevLastNameSpecialCharacters !== specialCharacters.join('')
                ) {
                  prevLastNameSpecialCharacters = specialCharacters.join('');
                  console.log(
                    'prevLastNameSpecialCharacters',
                    prevLastNameSpecialCharacters
                  );
                  trackEvent({
                    name: 'Add Insurance Inline Error Surfaced',
                    properties: {
                      patientUserId: client.id,
                      providerId: provider.id,
                      insuranceElement: 'last_name',
                      insuranceElementErrorDescription: 'invalid_character',
                      insuranceElementErrorString: specialCharacters,
                      insuranceSubmissionBlocked: true,
                    },
                  });
                }
                return false; // Validation failed
              }
              prevLastNameSpecialCharacters = null;
              return true; // Validation passed
            }
          ),
      }),
    dob: Yup.date()
      .required('Date of birth is required')
      .transform((_value, originalValue: string | Date | null): Date | null => {
        if (typeof originalValue === 'string') {
          return moment(originalValue).toDate();
        }

        return originalValue;
      })
      .nullable()
      .min(moment('1900-01-01').toDate(), 'Must be a valid year.')
      .max(moment().toDate(), 'Must be before today.'),
    insuranceCarrierId: Yup.number().required('Insurance carrier is required'),
    memberId: Yup.string().required('Member ID is required'),
    groupNumber: Yup.string(),
  });

export const selfPayFormValidation = Yup.object().shape({
  selfPayRateDefault: Yup.number()
    .min(0, 'Must be $0 or more.')
    .max(MAX_SELF_PAY_RATE, MAX_SELF_PAY_RATE_MESSAGE)
    .test(
      'nonzero-minimum',
      MIN_NONZERO_SELF_PAY_RATE_MESSAGE,
      (value) => !value || value === 0 || value >= MIN_NONZERO_SELF_PAY_RATE
    )
    .required('Private Pay rate is required'),
  confirmComms: Yup.boolean().oneOf(
    [true],
    'You must acknowledge that Headway will send communications to this client.'
  ),
  confirmNotInNetwork: Yup.boolean().oneOf(
    [true],
    'You must confirm that the client is either not in-network or not interested in in-network care before continuing.'
  ),
});

export const selfPayEnablementFormValidation = Yup.object({
  acceptedTerms: Yup.boolean().oneOf(
    [true],
    'You must accept the private pay terms before continuing'
  ),
  taxId: Yup.string()
    .notRequired()
    .test(
      'nineDigits',
      'EIN must be 9 digits if provided',
      (val) => !val || val === '__-_______' || /^\d{2}-\d{7}$/.test(val)
    ),
});

export const billingTypeFormIntialValues = (
  client: UserRead,
  newHumanInputExperienceEnabled: boolean
) => ({
  firstName: client?.activeUserInsurance?.firstName || client?.firstName || '',
  lastName: client?.activeUserInsurance?.lastName || client?.lastName || '',
  dob: client?.activeUserInsurance?.dob
    ? moment(client?.activeUserInsurance?.dob).toISOString()
    : '',
  insuranceCarrierId: client?.activeUserInsurance?.frontEndCarrierId,
  memberId: client?.activeUserInsurance?.memberId || '',
  groupNumber: client?.activeUserInsurance?.groupNumber || '',
  newHumanInputExperienceEnabled: newHumanInputExperienceEnabled,
});

export const selfPayFormInitialValues = (
  providerPatient: ProviderPatientRead
) => {
  if (!providerPatient) {
    return;
  }

  return {
    selfPayRateDefault: providerPatient.selfPayRateDefault || 100,
    confirmComms: !!providerPatient.providerSelfPayAttestationDate || false,
    confirmNotInNetwork:
      !!providerPatient.providerSelfPayAttestationDate || false,
  };
};

export const selfPayEnablementInitialValues = (provider: ProviderRead) => ({
  acceptedTerms: false,
  taxId: provider?.taxId || '',
});

export const AddBillingInformationForm = ({
  client,
  providerPatient,
  onBillingAdded,
}: AddBillingInformationFormProps) => {
  const telehealthLocationsEnabled = useFlag(
    TELEHEALTH_LOCATIONS_SIGMUND,
    false
  );
  const newHumanInputExperienceEnabled = useFlag(
    NEW_HUMAN_INPUT_ERROR_PRODUCT_EXPERIENCE,
    false
  );
  const newMemberIdValidationExperienceEnabled = useFlag(
    NEW_MEMBER_ID_VALIDATION_EXPERIENCE,
    false
  );
  const provider = useProvider();
  const authStore = useAuthStore();
  const {
    frontEndCarriers: allFrontEndCarriers,
    carriersById: frontEndCarriersById,
  } = useFrontEndCarriers();
  const providerFrontEndCarrierContext = React.useContext(
    ProviderFrontEndCarrierContext
  );
  const [
    carrierToShowAuthorizationInstructionsFor,
    setCarrierToShowAuthorizationInstructionsFor,
  ] = useState<FrontEndCarrierRead | FrontEndCarrierNested>();
  const { insuranceStatus } = useInsuranceStatus(
    client,
    client.activeUserInsurance
  );

  let selfPayEligible: boolean = false;
  if (insuranceStatus === PatientInsuranceOrEAPStatus.NO_INSURANCE) {
    selfPayEligible =
      client.inviteChannel === UserInviteChannel.PROVIDER_PORTAL;
  } else {
    selfPayEligible =
      insuranceStatus !== PatientInsuranceOrEAPStatus.IN_NETWORK;
  }

  const [didAttemptSelfPayOptIn, setDidAttemptSelfPayOptIn] = useState(false);

  const [refreshedEligibilityLookup, setRefreshedEligibilityLookup] = useState<
    EligibilityLookupRead | undefined
  >(undefined);

  const errorCodes: ErrorCode[] = refreshedEligibilityLookup?.errorCodes || [];
  const otherErrors: string[] = refreshedEligibilityLookup?.otherErrors || [];

  const {
    [LookupErrorCategory.GENERIC]: shouldShowGenericLookupError,
    [LookupErrorCategory.SPECIFIC]: shouldShowSpecificLookupError,
    [LookupErrorCategory.NAME]: shouldShowNameLookupError,
    [LookupErrorCategory.MEMBER_ID]: shouldShowMemberIdLookupError,
    [LookupErrorCategory.DATE_OF_BIRTH]: shouldShowDOBLookupError,
  } = useMemo(
    () =>
      shouldShowLookupErrors({
        otherLookupErrors: refreshedEligibilityLookup?.otherErrors || [],
        lookupErrorCodes: refreshedEligibilityLookup?.errorCodes || [],
        formikErrors: {},
        newHumanInputErrorExperienceEnabled: newHumanInputExperienceEnabled,
      }),
    [refreshedEligibilityLookup, newHumanInputExperienceEnabled]
  );

  useEffect(() => {
    if (
      refreshedEligibilityLookup &&
      (shouldShowGenericLookupError ||
        shouldShowSpecificLookupError ||
        shouldShowNameLookupError ||
        shouldShowMemberIdLookupError ||
        shouldShowDOBLookupError)
    ) {
      trackEvent({
        name: 'Add Insurance Human Input Error Surfaced',
        properties: {
          providerId: provider.id,
          eligibilityLookupId: refreshedEligibilityLookup.id,
          patientUserId: client.id,
        },
      } as AddInsuranceHumanInputErrorSurfacedEvent);
    }
  }, [
    provider,
    refreshedEligibilityLookup,
    client,
    shouldShowGenericLookupError,
    shouldShowSpecificLookupError,
    shouldShowNameLookupError,
    shouldShowMemberIdLookupError,
    shouldShowDOBLookupError,
  ]);

  const nameLookupError = errorCodes?.find(
    (errorCode) => categorizeLookupError(errorCode) === LookupErrorCategory.NAME
  );
  const dobLookupError = errorCodes?.find(
    (errorCode) =>
      categorizeLookupError(errorCode) === LookupErrorCategory.DATE_OF_BIRTH
  );
  const memberIdLookupError = errorCodes?.find(
    (errorCode) =>
      categorizeLookupError(errorCode) === LookupErrorCategory.MEMBER_ID
  );

  const noTelehealthExperienceEnabled = useFlag(
    ENABLE_NO_TELEHEALTH_EXPERIENCE,
    false
  );

  const benefitsMaxEnabled = useFlag(
    USE_BENEFITS_MAX_FOR_CLAIM_READINESS,
    false
  );

  selfPayEligible =
    selfPayEligible ||
    !!client.activeUserInsurance?.latestEligibilityLookup?.outOfNetwork ||
    (!!client.activeUserInsurance?.latestEligibilityLookup
      ?.hasNoTelehealthBenefits &&
      noTelehealthExperienceEnabled) ||
    hasNoRemainingSessions(client, benefitsMaxEnabled);

  const [isValidMemberId, setIsValidMemberId] = useState(true);
  const [isMemberIdChecked, setIsMemberIdChecked] = useState(false);
  const {
    isLoading: isProviderEventsUnconfirmedLoading,
    providerEventsUnconfirmed,
    error,
  } = useProviderEventsUnconfirmedQuery(providerPatient);

  const unconfirmedCount = providerEventsUnconfirmed
    ? providerEventsUnconfirmed.totalCount
    : 0;
  const shouldShowAlert = error || unconfirmedCount > 0;

  const checkMemberId = debounce((carrierId: number, memberId: string) => {
    if (!!getCarrierRegex(carrierId)) {
      const matches = memberId.match(getCarrierRegex(carrierId).regex);
      setIsValidMemberId(!matches ? false : true);
      setIsMemberIdChecked(true);
    }
  }, 500);

  // Cleanup async debounce function
  useEffect(() => {
    return () => {
      checkMemberId.cancel();
    };
  }, [checkMemberId]);

  const { setCurrentStep } = useContext(AddPatientModalContext);

  const performEligibilityLookup = async (
    client: UserRead,
    userId: number,
    userInsuranceId: number
  ) => {
    try {
      return await EligibilityLookupApi.refreshUserEligibilityLookup(
        userId,
        userInsuranceId,
        { lookup_source: LookupSource.PROVIDER_INSURANCE_UPLOAD }
      );
    } catch (err) {
      notifyWarning(
        `There was a problem verifying ${formatPatientName(client, {
          firstNameOnly: true,
        })}'s insurance coverage`
      );
      logException(err);
    }
  };

  const onSkip = () => {
    setCurrentStep(
      telehealthLocationsEnabled
        ? AddPatientModalPage.TELEHEALTH_LOCATIONS
        : AddPatientModalPage.SEND_ACCOUNT_INVITE
    );
  };

  const onBillingDetailsComplete = (billingType: BillingType) => {
    setCurrentStep(
      telehealthLocationsEnabled && billingType === BillingType.INSURANCE
        ? AddPatientModalPage.TELEHEALTH_LOCATIONS
        : AddPatientModalPage.SEND_ACCOUNT_INVITE
    );
  };

  const onUpdateSuccess = async ({
    userRead: updatedUser,
    billingType,
    userInsurance,
    eligibilityLookup,
  }: InsuranceFormUpdate) => {
    if (updatedUser.activeUserInsuranceId) {
      try {
        if (
          newHumanInputExperienceEnabled &&
          isFuzzyMatchedLookup(eligibilityLookup, userInsurance)
        ) {
          setCurrentStep(AddPatientModalPage.VERIFY_INSURANCE_MATCH);
        } else {
          // TODO(michael): paused lookups
          const readiness = await UserApi.getClaimReadiness(updatedUser.id);
          if (
            readiness.requirements?.includes(
              UserClaimReadinessCheck.PATIENT_ADDRESS
            )
          ) {
            setCurrentStep(AddPatientModalPage.PATIENT_ADDRESS);
          } else {
            onBillingDetailsComplete(billingType);
          }
        }
      } catch (e) {
        logException(e);
        onBillingDetailsComplete(billingType);
      }
    } else {
      onBillingDetailsComplete(billingType);
    }
    onBillingAdded(updatedUser);
  };

  const patientInsuranceFormSubmit = async (
    values: AddBillingInformationFormValues,
    client: UserRead
  ): Promise<InsuranceFormUpdate | undefined> => {
    const {
      firstName,
      lastName,
      memberId,
      groupNumber,
      insuranceCarrierId,
      dob,
    } = values;
    try {
      const newInsurance = await UserInsuranceApi.createUserInsurance({
        userId: client.id,
        firstName,
        lastName,
        memberId,
        groupNumber,
        frontEndCarrierId: insuranceCarrierId!,
        frontEndCarrierName: frontEndCarriersById[insuranceCarrierId!].name!,
        dob: moment(dob).format('YYYY-MM-DD'),
      });

      const lookup = await performEligibilityLookup(
        client,
        client.id,
        newInsurance.id
      );

      // This is deliberately being called after the eligibility lookup so that the state is set correctly
      const updatedUser = await UserApi.updateUser(client.id, {
        activeUserInsuranceId: newInsurance.id,
      });

      return {
        userRead: updatedUser,
        billingType: values.billingTypeDefault,
        userInsurance: updatedUser.activeUserInsurance,
        eligibilityLookup: lookup,
      };
    } catch (err) {
      notifyError(
        `There was a problem saving ${formatPatientName(client, {
          firstNameOnly: true,
        })}'s insurance`
      );
      logException(err);
    }
  };

  const updateBillingInfo = async (
    values: AddBillingInformationFormValues
  ): Promise<InsuranceFormUpdate | undefined> => {
    if (values.billingTypeDefault === BillingType.SELF_PAY) {
      try {
        const payload: ProviderPatientUpdate = {
          billingTypeDefault: values.billingTypeDefault,
          selfPayRateDefault: values.selfPayRateDefault,
        };

        if (!providerPatient.providerSelfPayAttestationDate) {
          payload.providerSelfPayAttestationDate = new Date().toISOString();
        }
        await ProviderPatientApi.updateProviderPatient(
          providerPatient.id,
          payload
        );
        return {
          userRead: client,
          billingType: values.billingTypeDefault,
        };
      } catch (err) {
        notifyError(
          `There was a problem saving ${formatPatientName(client, {
            firstNameOnly: true,
          })}'s billing information`
        );
        logException(err);
        throw err;
      }
    } else {
      if (providerPatient.billingTypeDefault !== values.billingTypeDefault) {
        await ProviderPatientApi.updateProviderPatient(providerPatient.id, {
          billingTypeDefault: values.billingTypeDefault,
        });
      }
      return await patientInsuranceFormSubmit(values, client);
    }
  };

  const updateEnableSelfPay = async (
    values: AddBillingInformationFormValues,
    setFieldError: FormikHelpers<AddBillingInformationFormValues>['setFieldError']
  ) => {
    try {
      const updatedProvider = await ProviderApi.updateProvider(provider.id, {
        selfPayTermsAcceptanceDate: new Date().toISOString(),
        ...(values.taxId ? { taxId: values.taxId } : {}),
      });
      authStore.setProvider(updatedProvider);
      setDidAttemptSelfPayOptIn(true);
    } catch (err: any) {
      if (
        err?.response?.status === 400 &&
        err?.response?.data?.detail === TAX_ID_SSN_MATCH_ERROR
      ) {
        // API returns a 400 if the EIN and SSN match.
        setFieldError('taxId', 'EIN must not match Social Security number');
      } else {
        notifyError('There was a problem enabling private pay.');
        logException(err);
      }
    }
  };

  const enablingSelfPay = (billingType: BillingType) =>
    billingType === BillingType.SELF_PAY &&
    !provider?.selfPayTermsAcceptanceDate;

  const handleBillingInformationFormSubmit = async (
    values: AddBillingInformationFormValues,
    formikHelpers: FormikHelpers<AddBillingInformationFormValues>
  ) => {
    const { setFieldError, setSubmitting } = formikHelpers;
    setSubmitting(true);
    try {
      if (enablingSelfPay(values.billingTypeDefault)) {
        await updateEnableSelfPay(values, setFieldError);
      } else {
        const result = await updateBillingInfo(values);

        if (!result) {
          setSubmitting(false);
          return;
        }

        if (
          newHumanInputExperienceEnabled &&
          values.billingTypeDefault === BillingType.INSURANCE
        ) {
          setRefreshedEligibilityLookup(result.eligibilityLookup);

          const hasLookupErrors =
            result.eligibilityLookup &&
            ((result.eligibilityLookup.otherErrors?.length ?? 0) > 0 ||
              (result.eligibilityLookup.errorCodes?.length ?? 0) > 0);

          if (hasLookupErrors) {
            setSubmitting(false);
            return; // Stop here if there are lookup errors
          }
        }

        // If we've made it here, there are no errors, so we can proceed
        onUpdateSuccess(result);
      }
    } catch (error) {
      logException(error);
      notifyError('An error occurred while submitting the form.');
    } finally {
      setSubmitting(false);
    }
  };

  const firstName = formatFirstName(client);

  const initialValues = {
    billingTypeDefault: didAttemptSelfPayOptIn
      ? BillingType.SELF_PAY
      : providerPatient && providerPatient.billingTypeDefault
      ? providerPatient.billingTypeDefault
      : BillingType.INSURANCE,
    ...billingTypeFormIntialValues(client!, newHumanInputExperienceEnabled),
    ...selfPayFormInitialValues(providerPatient!),
    ...selfPayEnablementInitialValues(provider!),
  };

  const validationSchema = Yup.lazy(
    (
      values: Yup.Shape<object | undefined, AddBillingInformationFormValues>
    ): any => {
      return values?.billingTypeDefault === BillingType.INSURANCE
        ? clientBillingTypeFormValidation({ provider, client })
        : values?.billingTypeDefault === BillingType.SELF_PAY &&
          !provider?.selfPayTermsAcceptanceDate
        ? selfPayEnablementFormValidation
        : selfPayFormValidation;
    }
  );
  const patientNameFormatted = formatPatientName(client, {
    firstNameOnly: true,
  });

  // Use useRef to store the previous special characters so we only log when special
  //  characters change
  const prevFirstNameSoftSpecialCharactersRef = useRef<string | null>(null);
  const prevLastNameSoftSpecialCharactersRef = useRef<string | null>(null);
  const showSpecialCharactersWarning = (
    values: AddBillingInformationFormValues,
    errors: any
  ) => {
    if (!newHumanInputExperienceEnabled) {
      return false;
    }
    const firstNameSpecialCharacters = values.firstName.match(/[ '.-]/g);
    const lastNameSpecialCharacters = values.lastName.match(/[ '.-]/g);
    if (firstNameSpecialCharacters) {
      if (
        prevFirstNameSoftSpecialCharactersRef.current !==
        firstNameSpecialCharacters.join('')
      ) {
        prevFirstNameSoftSpecialCharactersRef.current =
          firstNameSpecialCharacters.join('');
        trackEvent({
          name: 'Add Insurance Inline Error Surfaced',
          properties: {
            patientUserId: client.id,
            providerId: provider.id,
            insuranceElement: 'first_name',
            insuranceElementErrorDescription: 'suspected_invalid_character',
            insuranceElementErrorString: [...firstNameSpecialCharacters],
            insuranceSubmissionBlocked: false,
          },
        });
      }
    } else {
      prevFirstNameSoftSpecialCharactersRef.current = null;
    }
    if (lastNameSpecialCharacters) {
      if (
        prevLastNameSoftSpecialCharactersRef.current !==
        lastNameSpecialCharacters.join('')
      ) {
        prevLastNameSoftSpecialCharactersRef.current =
          lastNameSpecialCharacters.join('');
        trackEvent({
          name: 'Add Insurance Inline Error Surfaced',
          properties: {
            patientUserId: client.id,
            providerId: provider.id,
            insuranceElement: 'last_name',
            insuranceElementErrorDescription: 'suspected_invalid_character',
            insuranceElementErrorString: [...lastNameSpecialCharacters],
            insuranceSubmissionBlocked: false,
          },
        });
      }
    } else {
      prevLastNameSoftSpecialCharactersRef.current = null;
    }
    return (
      (!!firstNameSpecialCharacters || !!lastNameSpecialCharacters) &&
      !errors?.firstName &&
      !errors?.lastName
    );
  };
  return (
    <Formik<AddBillingInformationFormValues>
      validateOnMount={true}
      onSubmit={handleBillingInformationFormSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      {({ values, errors, isSubmitting }) =>
        isProviderEventsUnconfirmedLoading ? (
          <div className="mt-8 flex flex-col items-center gap-8">
            <SectionHeader>Saving...</SectionHeader>
            <div>
              <LogoLoader />
            </div>
          </div>
        ) : (
          <>
            <ModalContent>
              <SafeFormikForm
                id="add-billing-information-form"
                style={{
                  gap: 0,
                  height: '100%',
                }}
              >
                <PageSection>
                  <div
                    css={{
                      [theme.__futureMedia.below('tablet')]: {
                        width: '100%',
                        minWidth: '0',
                      },
                    }}
                  >
                    <div>
                      {firstName && (
                        <div className="mb-6 flex items-center justify-start">
                          <CheckCircle
                            css={{
                              color: theme.color.system.green,
                              marginRight: theme.spacing.x1,
                            }}
                          />
                          <strong>{`${firstName}'s been added as a client.`}</strong>
                        </div>
                      )}
                      {shouldShowAlert && (
                        <div className="mt-4">
                          <PatientBillingUnconfirmedWarning
                            error={error}
                            unconfirmedCount={unconfirmedCount}
                          />
                        </div>
                      )}
                      <SectionHeader>Add Billing Information</SectionHeader>
                      <div className="mt-4">
                        <BodyText>
                          {firstName &&
                          values.billingTypeDefault !== BillingType.SELF_PAY
                            ? `You may fill out ${firstName}'s billing information now, or skip this
                    step and ask them to add this information when they set up their
                    Headway account.`
                            : `You can edit the private pay rate to customize this client's rate if needed.`}
                        </BodyText>
                      </div>
                      {selfPayEligible && (
                        <div className="mt-6">
                          <FormRow>
                            <FormControl
                              name="billingTypeDefault"
                              component={BigRadioGroup}
                              aria-label="Billing type"
                              label="Billing type"
                              onChange={(value: string) => {
                                trackEvent({
                                  name: 'Add Patient Step Viewed',
                                  properties: {
                                    screenName: 'Add billing information',
                                    stepName: value,
                                  },
                                });
                              }}
                            >
                              <div css={{ flex: 1 }}>
                                <BigRadio
                                  key="insurance"
                                  value={BillingType.INSURANCE}
                                >
                                  <div className="flex flex-col">
                                    <strong>Insurance</strong>
                                    <BodyText color="gray">
                                      Bill the client's insurance plan
                                    </BodyText>
                                  </div>
                                </BigRadio>
                              </div>
                              <div css={{ flex: 1 }}>
                                <BigRadio
                                  key="selfpay"
                                  value={BillingType.SELF_PAY}
                                >
                                  <div className="flex flex-col">
                                    <strong>Private pay</strong>
                                    <BodyText color="gray">
                                      Only bill the client's payment method
                                    </BodyText>
                                  </div>
                                </BigRadio>
                              </div>
                            </FormControl>
                          </FormRow>
                        </div>
                      )}

                      {values.billingTypeDefault === BillingType.INSURANCE && (
                        <div className="mt-6">
                          <ListHeader>Add client insurance details</ListHeader>
                          {!!carrierToShowAuthorizationInstructionsFor && (
                            <Modal
                              isOpen={
                                !!carrierToShowAuthorizationInstructionsFor
                              }
                              onDismiss={() => {
                                setCarrierToShowAuthorizationInstructionsFor(
                                  undefined
                                );
                              }}
                              title={getAuthorizationInstructionsModalTitle(
                                patientNameFormatted
                              )}
                            >
                              <InsuranceAuthorizationInstructionsModalContent
                                carrier={
                                  carrierToShowAuthorizationInstructionsFor
                                }
                                clientDisplayName={client.firstName}
                                closeModal={() => {
                                  setCarrierToShowAuthorizationInstructionsFor(
                                    undefined
                                  );
                                }}
                              />
                            </Modal>
                          )}
                          <div className="mt-4">
                            {newHumanInputExperienceEnabled && (
                              <>
                                {shouldShowGenericLookupError && (
                                  <div className="mb-4">
                                    <GuidanceCard
                                      variant="error"
                                      dataTestId="generic-lookup-error"
                                    >
                                      <SubBodyText>
                                        {getGenericLookupErrorMessage()}
                                      </SubBodyText>
                                    </GuidanceCard>
                                  </div>
                                )}
                                {shouldShowSpecificLookupError && (
                                  <div className="mb-4">
                                    <GuidanceCard
                                      variant="error"
                                      dataTestId="specific-lookup-error"
                                    >
                                      <SubBodyText>
                                        {getErrorMessageFromString(
                                          otherErrors[0]
                                        )
                                          .split('\n')
                                          .map(
                                            (line: string, index: number) => (
                                              <React.Fragment key={index}>
                                                {line}
                                                <br />
                                              </React.Fragment>
                                            )
                                          )}
                                      </SubBodyText>
                                    </GuidanceCard>
                                  </div>
                                )}
                              </>
                            )}
                            <FormRow>
                              <FormControl
                                component={TextField}
                                label="Legal first name"
                                data-testid="legal-first-name-input"
                                name="firstName"
                                aria-describedby="name-help-text"
                              />
                              <FormControl
                                component={TextField}
                                label="Legal last name"
                                data-testid="legal-last-name-input"
                                name="lastName"
                                aria-describedby="name-help-text"
                              />
                            </FormRow>
                            {newHumanInputExperienceEnabled &&
                              shouldShowNameLookupError &&
                              nameLookupError && (
                                <div className="m-4">
                                  <GuidanceCard
                                    variant="error"
                                    dataTestId="name-lookup-error"
                                  >
                                    <SubBodyText>
                                      {getErrorMessageFromCode(nameLookupError)}
                                    </SubBodyText>
                                  </GuidanceCard>
                                </div>
                              )}
                            {showSpecialCharactersWarning(values, errors) && (
                              <div
                                css={{ margin: theme.spacing.x3 }}
                                data-testid="special-characters-warning"
                              >
                                <GuidanceCard variant="warning">
                                  <SubBodyText>
                                    Some names contain special characters
                                    (hyphens, apostrophes, etc.) but please
                                    double check this matches the spelling on
                                    your card.
                                  </SubBodyText>
                                </GuidanceCard>
                              </div>
                            )}
                            <div id="name-help-text">
                              <SubBodyText>
                                Enter your client's name as it's listed on the
                                insurance card so we can process claims
                                smoothly.
                              </SubBodyText>
                            </div>
                          </div>
                          <div className="mt-4 grid gap-4">
                            <FormControl
                              component={DateField}
                              label="Date of birth"
                              data-testid="dob-input"
                              name="dob"
                            />
                            {newHumanInputExperienceEnabled &&
                              shouldShowDOBLookupError &&
                              dobLookupError && (
                                <div className="m-4">
                                  <GuidanceCard
                                    variant="error"
                                    dataTestId="dob-lookup-error"
                                  >
                                    <SubBodyText>
                                      {getErrorMessageFromCode(dobLookupError)}
                                    </SubBodyText>
                                  </GuidanceCard>
                                </div>
                              )}
                            <FormControl
                              component={Select}
                              label="Insurance carrier"
                              name="insuranceCarrierId"
                              data-testid="insurance-carrier-select"
                              selectionMode="single"
                              menuWidth="stretch"
                              selectedKeys={
                                values.insuranceCarrierId
                                  ? [values.insuranceCarrierId]
                                  : []
                              }
                              onSelectionChange={(value: Set<string>) => {
                                const insuranceCarrierId = Number(
                                  Array.from(value)[0]
                                );
                                if (insuranceCarrierId) {
                                  checkMemberId(
                                    insuranceCarrierId,
                                    values.memberId
                                  );
                                }
                              }}
                            >
                              {providerFrontEndCarrierContext.providerFrontEndCarriers
                                .map((pfec) => pfec.frontEndCarrier)
                                .concat(
                                  providerFrontEndCarrierContext.providerFrontEndCarriers.some(
                                    (pfec) =>
                                      isFrontEndCarrierIdBcbs(
                                        pfec.frontEndCarrierId
                                      )
                                  )
                                    ? allFrontEndCarriers.filter((fec) =>
                                        isFrontEndCarrierIdBcbs(fec.id)
                                      )
                                    : []
                                )
                                .reduce(
                                  (carriers: FrontEndCarrierNested[], pfec) =>
                                    carriers.concat(
                                      pfec,
                                      allFrontEndCarriers.filter(
                                        (fec) =>
                                          fec.parentFrontEndCarrierId ===
                                          pfec.id
                                      )
                                    ),
                                  []
                                )
                                .sort((fec1, fec2) =>
                                  fec1.name.localeCompare(fec2.name)
                                )
                                .filter(
                                  (carrier, idx, array) =>
                                    array.findIndex(
                                      (c) => c.id === carrier.id
                                    ) === idx
                                ) // take out duplicate values
                                .map((carrier: any, idx: number) => {
                                  return (
                                    <Item
                                      data-testid={`patientInsuranceCarrierOption-${carrier.name}`}
                                      key={Number(carrier.id)}
                                    >
                                      {carrier.name}
                                    </Item>
                                  );
                                })}
                            </FormControl>
                            {values.insuranceCarrierId &&
                            CARRIERS_REQUIRING_AUTHORIZATION.includes(
                              Number(values.insuranceCarrierId)
                            ) ? (
                              <InsuranceNeedsAuthzGuidanceCard
                                firstName={values.firstName}
                                carrier={
                                  providerFrontEndCarrierContext
                                    .providerFrontEndCarriersById[
                                    values.insuranceCarrierId
                                  ].frontEndCarrier
                                }
                                showInsuranceAuthorizationInstructionsModal={
                                  setCarrierToShowAuthorizationInstructionsFor
                                }
                              />
                            ) : (
                              <>
                                <MemberIdInput
                                  patient={client}
                                  provider={provider}
                                  newMemberIdValidationExperienceEnabled={
                                    newMemberIdValidationExperienceEnabled
                                  }
                                  insuranceCarrierId={values.insuranceCarrierId}
                                  memberId={values.memberId}
                                  isMemberIdChecked={isMemberIdChecked}
                                  isValidMemberId={isValidMemberId}
                                  checkMemberId={checkMemberId}
                                />
                                {newHumanInputExperienceEnabled &&
                                  shouldShowMemberIdLookupError &&
                                  memberIdLookupError && (
                                    <div className="m-4">
                                      <GuidanceCard
                                        variant="error"
                                        dataTestId="member-id-lookup-error"
                                      >
                                        <SubBodyText>
                                          {getErrorMessageFromCode(
                                            memberIdLookupError
                                          )}
                                        </SubBodyText>
                                      </GuidanceCard>
                                    </div>
                                  )}

                                <FormControl
                                  component={TextField}
                                  label="Group ID"
                                  name="groupNumber"
                                />
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {enablingSelfPay(values.billingTypeDefault) && (
                        <EnableSelfPayForm
                          errors={errors}
                          isSubmitting={isSubmitting}
                        />
                      )}
                      {values.billingTypeDefault === BillingType.SELF_PAY &&
                        !!provider?.selfPayTermsAcceptanceDate && (
                          <div className="mt-6">
                            <FormControl
                              component={CurrencyField}
                              label="Session rate"
                              name="selfPayRateDefault"
                            />
                            <div className="mt-6 grid gap-4">
                              <FormControl
                                component={Checkbox}
                                name="confirmComms"
                              >
                                I acknowledge that this client will receive
                                communications from Headway.
                              </FormControl>
                              <FormControl
                                component={Checkbox}
                                name="confirmNotInNetwork"
                              >
                                I attest that, to the best of my knowledge, this
                                client is not covered by an insurance with which
                                Headway is in-network.
                              </FormControl>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </PageSection>
              </SafeFormikForm>
            </ModalContent>
            <ModalFooter>
              <Button variant="secondary" onPress={onSkip}>
                Skip
              </Button>
              <Button
                variant="primary"
                type="submit"
                form="add-billing-information-form"
                data-testid="add-billing-information-form-submit"
                disabled={
                  isSubmitting ||
                  (newHumanInputExperienceEnabled &&
                    !!Object.keys(errors).length)
                }
              >
                {values.billingTypeDefault &&
                enablingSelfPay(values.billingTypeDefault)
                  ? 'Enable private pay'
                  : 'Save and verify'}
              </Button>
            </ModalFooter>
          </>
        )
      }
    </Formik>
  );
};

const MemberIdInput = ({
  patient,
  provider,
  newMemberIdValidationExperienceEnabled,
  insuranceCarrierId,
  memberId,
  isMemberIdChecked,
  isValidMemberId,
  checkMemberId,
}: {
  patient: UserRead;
  provider: ProviderRead;
  newMemberIdValidationExperienceEnabled: boolean;
  insuranceCarrierId: number | undefined;
  memberId: string;
  isMemberIdChecked: boolean;
  isValidMemberId: boolean;
  checkMemberId: (carrierId: number, memberId: string) => void;
}) => {
  const [showValidationGuidance, setShowValidationGuidance] = useState(false);

  return (
    <>
      <FormControl
        component={TextField}
        label="Member ID"
        name="memberId"
        data-testid="member-id-input"
        onChange={(value: string) => {
          if (insuranceCarrierId && value) {
            checkMemberId(insuranceCarrierId, value);
          }
        }}
        onBlur={() => {
          if (newMemberIdValidationExperienceEnabled && insuranceCarrierId) {
            setShowValidationGuidance(true);
          }
        }}
      />
      {showValidationGuidance &&
        newMemberIdValidationExperienceEnabled &&
        insuranceCarrierId && (
          <MemberIdValidationGuidance
            patient={patient}
            provider={provider}
            memberId={memberId}
            frontEndCarrierId={Number(insuranceCarrierId)}
          />
        )}
      {!newMemberIdValidationExperienceEnabled &&
        insuranceCarrierId &&
        memberId &&
        isMemberIdChecked &&
        !isValidMemberId && (
          <div className="mb-2">
            <SubBodyText>
              {getCarrierHelperText(insuranceCarrierId)}
            </SubBodyText>
          </div>
        )}
    </>
  );
};
