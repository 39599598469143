import { TimeFields } from '@internationalized/date';
import partition from 'lodash/partition';
import React from 'react';

import { BillingType } from '@headway/api/models/BillingType';
import { CodingEnforcementErrorType } from '@headway/api/models/CodingEnforcementErrorType';
import { CodingEnforcementIssue } from '@headway/api/models/CodingEnforcementIssue';
import { CodingErrorLevel } from '@headway/api/models/CodingErrorLevel';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderAppointmentApi } from '@headway/api/resources/ProviderAppointmentApi';
import { CPTCodeInfo } from '@headway/shared/constants/cptCodes';
import { DXCodeInfo } from '@headway/shared/constants/diagnosisCodes';
import { QueryClient, useQuery } from '@headway/shared/react-query';
import { formatCodingEnforcementMessage } from '@headway/shared/utils/formatCodingEnforcementMessage';

import { convertTimeFieldsToDateString } from 'utils/date';

export enum CodingErrorLocation {
  /** Shown underneath the CPT code input */
  CPT_CODES = 'CPT_CODES',
  /** Shown underneath the diagnosis code input */
  DIAGNOSIS_CODES = 'DIAGNOSIS_CODES',
  /** Shown at top of confirm session details modal for overlapping appointments */
  SESSION_TIME = 'SESSION_TIME',
}

export interface CodingError {
  message: string | React.ReactNode;
  type: CodingEnforcementErrorType;
  preferredLocation: CodingErrorLocation;
}

export enum ValidationWarningLocation {
  /** Show next to the diagnosis and CPT code inputs */
  CODES = 'CODES',
  /** Show next to the submit button */
  SUBMIT = 'SUBMIT',
}

export interface ValidationWarning {
  showVerificationEntryPoint: boolean;
  location: ValidationWarningLocation;
  message: string | React.ReactNode;
}
export interface MiscodingResults {
  errors: CodingError[];
  warnings: ValidationWarning[];
}

const getMiscodingResultsQueryKey = (
  cptCodes: CPTCodeInfo[],
  diagnosisCodes: DXCodeInfo[],
  exactStartTime: TimeFields | undefined,
  exactEndTime: TimeFields | undefined,
  patient: UserRead,
  provider: ProviderRead,
  event: ProviderEventRead,
  prescriberPsychotherapyStartTime: TimeFields | undefined,
  prescriberPsychotherapyEndTime: TimeFields | undefined
) => [
  'appointment-validation-details',
  {
    cptCodes: cptCodes.map((code) => code.value),
    diagnosisCodes: diagnosisCodes.map((code) => code.value),
    startDate: event.startDate,
    timeZone: event.timeZone,
    providerId: provider.id,
    userId: patient.id,
    billingType: event?.providerAppointment?.billingType,
    eventId: event?.providerAppointment?.providerEventId,
    exactStartTime: exactStartTime
      ? convertTimeFieldsToDateString(
          new Date(event.startDate!),
          exactStartTime
        )
      : undefined,
    exactEndTime: exactEndTime
      ? convertTimeFieldsToDateString(new Date(event.startDate!), exactEndTime)
      : undefined,
    prescriberPsychotherapyStartTime: prescriberPsychotherapyStartTime
      ? convertTimeFieldsToDateString(
          new Date(event.startDate!),
          prescriberPsychotherapyStartTime
        )
      : undefined,
    prescriberPsychotherapyEndTime: prescriberPsychotherapyEndTime
      ? convertTimeFieldsToDateString(
          new Date(event.startDate!),
          prescriberPsychotherapyEndTime
        )
      : undefined,
  },
];

const getCodingErrors = (errors: CodingEnforcementIssue[]): CodingError[] =>
  errors.map((error) => ({
    message: formatCodingEnforcementMessage(error.errorType, error.args),
    type: error.errorType,
    preferredLocation: MISCODING_ERROR_LOCATION_MAP[error.errorType],
  }));

const getValidationWarnings = (
  warnings: CodingEnforcementIssue[]
): ValidationWarning[] =>
  warnings.map((warning) => {
    const location =
      warning.errorType ===
        CodingEnforcementErrorType.CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS ||
      warning.errorType === CodingEnforcementErrorType.ZCODE_WITHOUT_FCODE
        ? ValidationWarningLocation.CODES
        : ValidationWarningLocation.SUBMIT;
    return {
      showVerificationEntryPoint:
        warning.errorType === CodingEnforcementErrorType.ZCODE_WITHOUT_FCODE ||
        warning.errorType ===
          CodingEnforcementErrorType.CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS,
      location,
      message: formatCodingEnforcementMessage(warning.errorType, warning.args),
    };
  });

const miscodingResultsQueryFn = async (
  cptCodes: CPTCodeInfo[],
  diagnosisCodes: DXCodeInfo[],
  exactStartTime: TimeFields | undefined,
  exactEndTime: TimeFields | undefined,
  patient: UserRead,
  provider: ProviderRead,
  event: ProviderEventRead,
  prescriberPsychotherapyStartTime: TimeFields | undefined,
  prescriberPsychotherapyEndTime: TimeFields | undefined
): Promise<MiscodingResults> => {
  const { providerAppointment } = event;
  const miscodingResults =
    await ProviderAppointmentApi.createAppointmentValidationDetails({
      cptCodes: cptCodes.map((code) => code.value),
      diagnosisCodes: diagnosisCodes.map((code) => code.value),
      startDate: event.startDate!,
      endDate: event.endDate!,
      timeZone: event.timeZone,
      providerId: provider.id,
      userId: patient.id,
      billingType: providerAppointment?.billingType,
      eventId: providerAppointment?.providerEventId,
      exactStartTime: exactStartTime
        ? convertTimeFieldsToDateString(
            new Date(event.startDate!),
            exactStartTime
          )
        : undefined,
      exactEndTime: exactEndTime
        ? convertTimeFieldsToDateString(
            new Date(event.startDate!),
            exactEndTime
          )
        : undefined,
      prescriberPsychotherapyStartTime: prescriberPsychotherapyStartTime
        ? convertTimeFieldsToDateString(
            new Date(event.startDate!),
            prescriberPsychotherapyStartTime
          )
        : undefined,
      prescriberPsychotherapyEndTime: prescriberPsychotherapyEndTime
        ? convertTimeFieldsToDateString(
            new Date(event.startDate!),
            prescriberPsychotherapyEndTime
          )
        : undefined,
    });

  const [errors, warnings] = partition(
    miscodingResults,
    (result: any) => result.level === CodingErrorLevel.ERROR
  );
  return {
    errors:
      providerAppointment?.billingType === BillingType.SELF_PAY
        ? []
        : getCodingErrors(errors),
    warnings: getValidationWarnings(warnings),
  };
};

export const useMiscodingResultsQuery = (
  cptCodes: CPTCodeInfo[],
  diagnosisCodes: DXCodeInfo[],
  exactStartTime: TimeFields | undefined,
  exactEndTime: TimeFields | undefined,
  patient: UserRead,
  provider: ProviderRead,
  event: ProviderEventRead,
  prescriberPsychotherapyStartTime?: TimeFields | undefined,
  prescriberPsychotherapyEndTime?: TimeFields | undefined
) =>
  useQuery(
    getMiscodingResultsQueryKey(
      cptCodes,
      diagnosisCodes,
      exactStartTime,
      exactEndTime,
      patient,
      provider,
      event,
      prescriberPsychotherapyStartTime,
      prescriberPsychotherapyEndTime
    ),
    () =>
      miscodingResultsQueryFn(
        cptCodes,
        diagnosisCodes,
        exactStartTime,
        exactEndTime,
        patient,
        provider,
        event,
        prescriberPsychotherapyStartTime,
        prescriberPsychotherapyEndTime
      ),
    {
      keepPreviousData: true,
    }
  );

export const fetchMiscodingResults = async (
  queryClient: QueryClient,
  cptCodes: CPTCodeInfo[],
  diagnosisCodes: DXCodeInfo[],
  exactStartTime: TimeFields | undefined,
  exactEndTime: TimeFields | undefined,
  patient: UserRead,
  provider: ProviderRead,
  event: ProviderEventRead,
  prescriberPsychotherapyStartTime: TimeFields | undefined,
  prescriberPsychotherapyEndTime: TimeFields | undefined
): Promise<MiscodingResults> => {
  const key = getMiscodingResultsQueryKey(
    cptCodes,
    diagnosisCodes,
    exactStartTime,
    exactEndTime,
    patient,
    provider,
    event,
    prescriberPsychotherapyStartTime,
    prescriberPsychotherapyEndTime
  );
  const existing = queryClient.getQueryData<MiscodingResults>(key);
  if (existing) {
    return existing;
  }
  return queryClient.fetchQuery({
    queryKey: key,
    queryFn: () =>
      miscodingResultsQueryFn(
        cptCodes,
        diagnosisCodes,
        exactStartTime,
        exactEndTime,
        patient,
        provider,
        event,
        prescriberPsychotherapyStartTime,
        prescriberPsychotherapyEndTime
      ),
  });
};

const MISCODING_ERROR_LOCATION_MAP: {
  [errorType in CodingEnforcementErrorType]: CodingErrorLocation;
} = {
  [CodingEnforcementErrorType.CONCURRENT_CODE]: CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITH_E_AND_M]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITH_CRISIS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DUPLICATE_CONFIRM_SAME_DAY]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.E_AND_M_NEW_PATIENTS_ONLY]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.INTERACTIVE_COMPLEXITY_WITH_OTHER_CODES]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.FCODE_FIRST_REQUIRED]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.ZCODE_WITHOUT_FCODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.RCODE_WITHOUT_FCODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.MULTIPLE_F32_CODES]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.MULTIPLE_F33_CODES]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.CODE_NOT_SUPPORTED_BY_ALL_INSURANCE_PLANS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.HALLUCINATION_OUTSIDE_INTAKE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.OPTUM_EAP_DISALLOWED_CODE]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CIGNA_EAP_DISALLOWED_CODE]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.ANTHEM_EAP_DISALLOWED_CODE]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.ANTHEM_CA_DISALLOWED_CODE]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.NO_INSURANCE_ON_FILE]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.NO_EAP_ON_FILE]: CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.MULTIPLE_BASE_CODES]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.MULTIPLE_UNIQUE_ADD_ONS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.NO_CPT_CODES]: CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.MANIC_WITH_BIPOLAR]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.MANIC_WITH_DEPRESSIVE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.MANIC_WITH_RECURRENT]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.BIPOLAR_WITH_MANIC]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.BIPOLAR_WITH_DEPRESSIVE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.BIPOLAR_WITH_RECURRENT]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.DEPRESSIVE_WITH_MANIC]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.DEPRESSIVE_WITH_BIPOLAR]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.DEPRESSIVE_WITH_RECURRENT]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_6_MONTHS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_6_MONTHS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90791_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.DIAGNOSTIC_EVALUATION_CODES_90792_EVERY_12_MONTHS_ANTHEM_CA_YOUNGER_THAN_21]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.RECURRENT_WITH_MANIC]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.RECURRENT_WITH_BIPOLAR]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.RECURRENT_WITH_DEPRESSIVE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.EATING_DISORDER_WITH_ANOREXIA]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.EATING_DISORDER_WITH_POLYPHAGIA]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.EATING_DISORDER_WITH_FEEDING_PROBLEMS_OF_NEWBORN]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.PSYCHOTHERAPY_WITHOUT_E_AND_M]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PSYCHOTHERAPY_FOR_CRISIS_BY_ITSELF]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.G47_9_CANNOT_BE_PRIMARY]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.R46_81_EXCLUDES_F42]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.E66_09_EXCLUDES_DX]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.BMI_WITHOUT_OBESITY]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.G93_31_EXCLUDES_DX]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z56_9_WITH_PRIMARY_F_CODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z60_0_WITH_PRIMARY_F_CODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z63_0_WITH_PRIMARY_F_CODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z63_0_WITH_Z69_1]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z63_0_WITH_Z70_]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.Z63_4_WITH_PRIMARY_F_CODE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F84_0_WITH_F84_5]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F53_0_WITH_O90_6]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F64_1_WITH_F64_2]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F64_2_WITH_F64_0]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.ANOREXIA_NERVOSA_WITH_PSYCHOGENIC_LOSS_OF_APPETITE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F33_8_WITH_F30_]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F33_8_WITH_F31_]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.F43_8_IS_UNBILLABLE]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.R45_2_DISALLOWED]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.PHYSIOLOGICAL_CODE_REQUIRED]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.PANIC_WITH_AGORAPHOBIA]:
    CodingErrorLocation.DIAGNOSIS_CODES,
  [CodingEnforcementErrorType.CPT_90834_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90837_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90833_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90833_PSYCHOTHERAPY_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90836_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90836_PSYCHOTHERAPY_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90838_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90838_PSYCHOTHERAPY_DURATION_MISMATCH]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CPT_90833_90836_90838_MISSING_OR_UNREASONABLE_PSYCHOTHERAPY_TIMES]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.OVERLAPPING_APPOINTMENT]:
    CodingErrorLocation.SESSION_TIME,
  [CodingEnforcementErrorType.MULTIPLE_E_AND_M]: CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CRISIS_CODE_WITH_E_AND_M]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.CRISIS_CODE_WITH_E_AND_M_CRISIS]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PSYCH_ADDON_WITH_E_AND_M]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99213_90838_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99204_90838]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99204_90838_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99214_90838]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99214_90838_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90833]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90836]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90838]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90833_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90836_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99205_90838_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90833]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90836]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90838]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90833_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90836_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.PRESCRIBER_WARNING_99215_90838_90785]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.SESSION_LIMIT]: CodingErrorLocation.SESSION_TIME,
  [CodingEnforcementErrorType.E_AND_M_PSYCH_ADDON_SECOND_ONLY]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.E_AND_M_WITH_INTERACTIVE_COMPLEXITY_THIRD]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.E_AND_M_WITH_THIRD_WARNING]:
    CodingErrorLocation.CPT_CODES,
  [CodingEnforcementErrorType.NO_DIAGNOSIS_CODES]:
    CodingErrorLocation.DIAGNOSIS_CODES,
};
