import * as React from 'react';

export const WavingHand = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="#e8eaed"
    viewBox="0 -960 960 960"
    {...props}
  >
    <path d="M430-500l283-283q12-12 28-12t28 12q12 12 12 28t-12 28L487-444l-57-56zm99 99l254-255q12-12 28.5-12t28.5 12q12 12 12 28.5T840-599L586-345l-57-56zM211-211q-91-91-91-219t91-219l120-120 59 59q7 7 12 14.5t10 15.5l148-149q12-12 28.5-12t28.5 12q12 12 12 28.5T617-772L444-599l-85 84 19 19q46 46 44 110t-49 111l-57-56q23-23 25.5-54.5T321-440l-47-46q-12-12-12-28.5t12-28.5l57-56q12-12 12-28.5T331-656l-64 64q-68 68-68 162.5T267-267q68 68 163 68t163-68l239-240q12-12 28.5-12t28.5 12q12 12 12 28.5T889-450L649-211q-91 91-219 91t-219-91zm219-219zM680-39v-81q66 0 113-47t47-113h81q0 100-70.5 170.5T680-39zM39-680q0-100 70.5-170.5T280-921v81q-66 0-113 47t-47 113H39z"></path>
  </svg>
);
