import { UseQueryResult } from '@tanstack/react-query';
import zip from 'lodash/zip';

import { AuthorizationType } from '@headway/api/models/AuthorizationType';
import { BillingType } from '@headway/api/models/BillingType';
import { CalendarEventType } from '@headway/api/models/CalendarEventType';
import { InsuranceAuthorizationRead } from '@headway/api/models/InsuranceAuthorizationRead';
import { ProviderEventRead } from '@headway/api/models/ProviderEventRead';
import { SessionDetailsConfirmabilityResponse } from '@headway/api/models/SessionDetailsConfirmabilityResponse';
import { SessionDetailsEditabilityStatus } from '@headway/api/models/SessionDetailsEditabilityStatus';
import { UserClaimReadinessCheck } from '@headway/api/models/UserClaimReadinessCheck';
import { UserClaimReadinessResponse } from '@headway/api/models/UserClaimReadinessResponse';
import { UserFreezeReason } from '@headway/api/models/UserFreezeReason';
import { UserRead } from '@headway/api/models/UserRead';
import { ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useProviderUserFreezes } from '@headway/shared/hooks/useProviderUserFreezes';
import { useUserList } from '@headway/shared/hooks/useUser';
import { isPatientInNoDataPrelimPricing } from '@headway/shared/utils/prelimPricing';

import { useIroncladAgreementStatus } from 'hooks/useIroncladAgreementStatus';
import { useMatchingProviderFrontendCarriersForEvents } from 'hooks/useMatchingProviderFrontendCarriersForEvents';
import { isPastW9Deadline, shouldShowW9Components } from 'utils/billing';
import { shouldBlockProviderWithIroncladAgreement } from 'utils/ironcladAgreement';
import {
  isPatientMissingRequiredInfo,
  patientCanBookForBillingType,
} from 'utils/patient';
import {
  doesAppointmentHaveCrisisCode,
  isAppointmentDateValidForPFEC,
  isIntakeCall,
} from 'views/Calendar/events/util/events';

import { useBillingAccountForPayment } from './useBillingAccount';
import { useClaimReadinessList } from './useClaimReadiness';
import { useInsuranceAuthorizationsList } from './useInsuranceAuthorizations';
import { useProvider } from './useProvider';
import { useSessionDetailsConfirmabilityList } from './useSessionDetailsConfirmability';
import { useSessionDetailsEditabilityList } from './useSessionDetailsEditability';

export enum ReasonCannotConfirmSessionDetails {
  INDIVIDUAL_W9 = 1,
  BILLING_ACCOUNT_NOT_VERIFIED,
}

export interface UseConfirmSessionDetailsResult {
  canConfirmSessionDetails: boolean;
  isLoading: boolean;
  reasonCannotConfirm?: ReasonCannotConfirmSessionDetails;
}

/**
 * Returns `true` if we should allow the provider to open the session confirmation modal.
 * This hooks fails closed, so if it is still waiting on async data to determine the final result,
 * it returns `false`.
 */
export const useCanConfirmSessionDetails = (
  providerEvent: ProviderEventRead
): UseConfirmSessionDetailsResult => {
  return useCanConfirmSessionDetailsList([providerEvent])[0];
};

/**
 * Equivalent to `useCanConfirmSessionDetails` for a dynamic number of appointments.
 */
export const useCanConfirmSessionDetailsList = (
  providerEvents: Array<ProviderEventRead>
): UseConfirmSessionDetailsResult[] => {
  const isStandardPatientFormsEnabled = useFlag('standardPatientForms', true);
  const isIroncladBlockAppointmentConfirmationEnabled = useFlag(
    'ironcladBlockAppointmentConfirmation'
  );
  const shouldCollectIndividualW9FromGroup = useFlag(
    'shouldCollectIndividualW9FromGroup',
    false
  );
  const { ironcladAgreementStatus } = useIroncladAgreementStatus();
  const { billingAccountInfo } = useBillingAccountForPayment();
  const usersQueries = useUserList(
    providerEvents.map((event) => ({
      queryKeyArgs: { userId: event.patientUserId || NaN },
    }))
  );
  const sessionDetailsEditabilityQueries = useSessionDetailsEditabilityList(
    providerEvents.map((providerEvent) => ({
      eventId:
        providerEvent.calendarEventType === CalendarEventType.INSTANCE
          ? providerEvent.originalRecurringEventId
          : providerEvent.id,
      options: {
        refetchOnWindowFocus: false,
      },
    }))
  );
  const claimReadinessQueries = useClaimReadinessList(
    zip(providerEvents, usersQueries).map(([event, userQuery]) => {
      const { data: patient } = userQuery!;
      return {
        queryKeyArgs: { patientUser: patient },
        options: {
          enabled:
            !!patient?.activeUserInsuranceId &&
            event?.providerAppointment?.billingType === BillingType.INSURANCE,
        },
      };
    })
  );
  const sessionDetailsConfirmabilityApiQueries =
    useSessionDetailsConfirmabilityList(
      providerEvents.map((providerEvent) => ({
        queryKeyArgs: {
          providerEventId:
            providerEvent.calendarEventType === CalendarEventType.INSTANCE
              ? providerEvent.originalRecurringEventId
              : providerEvent.id,
          appointmentDate: new Date(providerEvent.startDate!),
        },
        options: {
          refetchOnWindowFocus: false,
        },
      }))
    );

  const validEAPQueries = useInsuranceAuthorizationsList(
    providerEvents.map((providerEvent) => ({
      queryKeyArgs: {
        query: providerEvent.patientUserId
          ? {
              user_id: providerEvent.patientUserId,
              authorization_type: AuthorizationType.EAP,
              has_unused_sessions: true,
            }
          : undefined,
      },
      options: { refetchOnWindowFocus: false },
    }))
  );

  const provider = useProvider();
  const { freezeReasonsByUser, isLoading: isProviderUserFreezesLoading } =
    useProviderUserFreezes(provider.id);

  const {
    eventIdsToMatchingProviderFrontEndCarriers,
    isLoading: isLoadingMatchingProviderFrontEndCarriers,
  } = useMatchingProviderFrontendCarriersForEvents(provider.id, providerEvents);

  const acceptAnthemBankOfAmericaEAP = useFlag(
    ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP,
    false
  );

  // lodash.zip can only take up to five arguments before it requires all arguments to have the same exact type, hence the as recasting that happens a few lines down.
  return zip<any>(
    providerEvents,
    usersQueries,
    sessionDetailsEditabilityQueries,
    claimReadinessQueries,
    sessionDetailsConfirmabilityApiQueries,
    validEAPQueries
  ).map(
    ([
      event,
      userQuery,
      sessionDetailsEditabilityQuery,
      claimReadinessQuery,
      sessionDetailsConfirmabilityApiQuery,
      validEAPQuery,
    ]) => {
      const { data: patient, isLoading: isUserQueryLoading } =
        userQuery! as UseQueryResult<UserRead, unknown>;
      const {
        data: sessionDetailsEditability,
        isLoading: isSessionDetailsEditabilityQueryLoading,
      } = sessionDetailsEditabilityQuery! as UseQueryResult<
        SessionDetailsEditabilityStatus,
        unknown
      >;
      const { data: claimReadiness, isLoading: isClaimReadinessLoading } =
        claimReadinessQuery! as UseQueryResult<
          UserClaimReadinessResponse,
          unknown
        >;
      const {
        data: sessionDetailsConfirmabilityApiCheck,
        isLoading: isSessionDetailsConfirmabilityApiCheckLoading,
      } = sessionDetailsConfirmabilityApiQuery! as UseQueryResult<
        SessionDetailsConfirmabilityResponse,
        unknown
      >;
      const { data: validEAPs, isLoading: isValidEAPQueryLoading } =
        validEAPQuery! as UseQueryResult<InsuranceAuthorizationRead[], unknown>;

      const isLoading =
        isUserQueryLoading ||
        isSessionDetailsEditabilityQueryLoading ||
        isClaimReadinessLoading ||
        isProviderUserFreezesLoading ||
        isSessionDetailsConfirmabilityApiCheckLoading ||
        isLoadingMatchingProviderFrontEndCarriers ||
        isValidEAPQueryLoading;
      const shouldBlockProviderFromConfirming =
        !!event?.startDate &&
        shouldBlockProviderWithIroncladAgreement(
          isIroncladBlockAppointmentConfirmationEnabled,
          ironcladAgreementStatus,
          new Date(event.startDate)
        );

      if (
        !patient ||
        !event ||
        !event.providerAppointment ||
        !sessionDetailsEditability ||
        !freezeReasonsByUser ||
        sessionDetailsEditability !== SessionDetailsEditabilityStatus.ALLOWED ||
        !patientCanBookForBillingType(
          patient,
          event.providerAppointment.billingType!,
          acceptAnthemBankOfAmericaEAP ? validEAPs ?? [] : []
        ) ||
        shouldBlockProviderFromConfirming ||
        !sessionDetailsConfirmabilityApiCheck?.isSessionConfirmable
      ) {
        return {
          canConfirmSessionDetails: false,
          isLoading,
        };
      }

      const missingRequiredPatientInfo = isPatientMissingRequiredInfo(
        patient,
        event.providerAppointment?.billingType!,
        claimReadiness
      );
      if (
        missingRequiredPatientInfo &&
        isStandardPatientFormsEnabled &&
        !isIntakeCall(event)
      ) {
        return { canConfirmSessionDetails: false, isLoading };
      }

      if (event.providerAppointment.billingType === BillingType.INSURANCE) {
        const matchingPFEC = event?.id
          ? eventIdsToMatchingProviderFrontEndCarriers.get(event.id)
          : undefined;
        const acceptsPatientInsurance =
          !!matchingPFEC &&
          isAppointmentDateValidForPFEC(matchingPFEC, event.startDate);
        const lookupProblemExists =
          claimReadiness &&
          !claimReadiness.ok &&
          !(
            isPatientInNoDataPrelimPricing(patient.activeUserInsurance) &&
            claimReadiness.requirements?.length === 1 &&
            claimReadiness.requirements?.includes(
              UserClaimReadinessCheck.PATIENT_ADDRESS
            )
          );
        const nonBlockingFreezeReasons = [
          UserFreezeReason.AWAITING_AUTOPAY_CX_ACTION,
        ];
        const hasBlockingFreezeReason = freezeReasonsByUser[patient.id]?.some(
          (reason) => !nonBlockingFreezeReasons.includes(reason)
        );
        const appointmentCptCodes = event.providerAppointment?.cptCodes || [];
        const hasCrisisCodes =
          doesAppointmentHaveCrisisCode(appointmentCptCodes);
        if (
          (!acceptsPatientInsurance && !hasCrisisCodes) ||
          lookupProblemExists ||
          ((freezeReasonsByUser[patient.id] || []).length > 0 &&
            hasBlockingFreezeReason)
        ) {
          return { canConfirmSessionDetails: false, isLoading };
        }
      }

      if (
        shouldShowW9Components(
          provider.groupPracticeId,
          shouldCollectIndividualW9FromGroup,
          billingAccountInfo?.stripeAccount
        ) &&
        isPastW9Deadline(provider.providerLicenseState.state)
      ) {
        return {
          canConfirmSessionDetails: false,
          isLoading,
          reasonCannotConfirm: ReasonCannotConfirmSessionDetails.INDIVIDUAL_W9,
        };
      }

      if (
        !billingAccountInfo ||
        (billingAccountInfo?.isGroupPracticeBillingAccount &&
          !billingAccountInfo.isVerified) ||
        // isVerified is the more accurate check for both GP and non-GP, but our demo account is a non-GP account
        // that isn't fully verified but has a bank account attached. bankAccount was our original check which should
        // be okay since our UI only allows bankAccount to be added after stripe is already verified. see also
        // https://therapymatch.slack.com/archives/C0452GCE8D8/p1698353826326909
        (!billingAccountInfo?.isGroupPracticeBillingAccount &&
          !billingAccountInfo?.bankAccount)
      ) {
        return {
          canConfirmSessionDetails: false,
          isLoading,
          reasonCannotConfirm:
            ReasonCannotConfirmSessionDetails.BILLING_ACCOUNT_NOT_VERIFIED,
        };
      }

      return { canConfirmSessionDetails: true, isLoading };
    }
  );
};
