import React from 'react';
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';

import { UserApi } from '@headway/api/resources/UserApi';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { Group } from '@headway/helix/Group';
import { Link as HelixLink } from '@headway/helix/Link';
import { LinkButton } from '@headway/helix/LinkButton';
import { ListHeader } from '@headway/helix/ListHeader';
import { TextField } from '@headway/helix/TextField';
import { toasts } from '@headway/helix/Toast';
import { PaymentsOutlined as PaymentsIcon } from '@headway/icons/dist/helix/navigation';
import { PROVIDER_REFERRAL_PROGRAM_AWARENESS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { usePayerQuestionnaire } from '@headway/shared/hooks/usePayerQuestionnaire';
import { useQuery } from '@headway/shared/react-query';
import {
  MEDICARE_ADVANTAGE_PARAM,
  OPTUM_MANAGED_MEDICAID_PARAM,
} from '@headway/shared/utils/postIntakeInfoCollection';
import { HumanTouchpoint } from '@headway/ui/HumanTouchpoint';
import { postIntakeInfoCollectionCSS } from '@headway/ui/postIntakeInfoCollection/PostIntakeInfoCollectionContent';

import blueArt from 'assets/img/blue-art.jpeg';
import Katie from 'assets/img/Katie.png';
import Mehek from 'assets/img/Mehek.png';

import { useAuthStore } from '../../stores/AuthStore';
import incentiveBrandAsset from './assets/incentive_mkt.png';
import missionBrandAsset from './assets/mission_mkt.png';

const HEADWAY_ACADEMY_URL =
  'https://headway-university.northpass.com/p/6057cd00cff8816f0f3debffbfdbee79dd16e134';

const MedicareAdvantageNextSteps = () => (
  <BodyText>
    <div className="grid gap-2">
      <strong>Next steps:</strong>
      <div>
        <p className="mb-0">Complete Headway Academy course</p>
        <p className="text-system-gray">
          Our clinical team has created a comprehensive guide to help make sure
          you’re ready to meet Medicare compliance requirements.
        </p>
      </div>
      <div className="grid gap-2">
        <LinkButton target="blank" href={HEADWAY_ACADEMY_URL} elementType="a">
          Start Headway Academy course
        </LinkButton>
        <LinkButton
          variant="secondary"
          component={Link}
          to="/home"
          elementType="a"
        >
          Go to provider portal
        </LinkButton>
      </div>
    </div>
  </BodyText>
);

const OptumManagedMedicaidCopy = () => (
  <BodyText>
    <div className="grid gap-2">
      <p>
        Great work! We’ll take the lead from here and keep you updated every
        step of the way. We will notify you over email if we need help
        completing your application.
      </p>
      <strong>Next steps:</strong>
      <div>
        <b className="mb-0">
          Complete government trainings through Headway Academy
        </b>
        <p className="text-system-gray">
          Our clinical team has created a comprehensive guide to make sure
          you’re ready to meet Medicare and Medicaid compliance requirements.
          You will not be able to see Medicare or Medicaid members until after
          you’ve completed the required compliance trainings.
        </p>
        <p className="text-system-gray">
          If you’ve completed this course for other Medicare or Medicaid plans
          on Headway, you do not need to complete it again.
        </p>
        <p>
          <b>Please note: </b>If you do not complete your government training,
          respond in a timely manner to any requests for additional information
          to help us complete your enrollment, or decide to cancel your
          application, you may be depaneled from Optum commercial.
        </p>
      </div>
      <div className="grid gap-2">
        <LinkButton target="blank" href={HEADWAY_ACADEMY_URL} elementType="a">
          Start course
        </LinkButton>
        <LinkButton
          variant="secondary"
          component={Link}
          to="/home"
          elementType="a"
        >
          Go to provider portal
        </LinkButton>
      </div>
    </div>
  </BodyText>
);

const SubmissionSuccessControl = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMedicareAdvantage =
    queryParams.get(MEDICARE_ADVANTAGE_PARAM) === 'true';
  const isOptumManagedMedicaid =
    queryParams.get(OPTUM_MANAGED_MEDICAID_PARAM) === 'true';

  // If user is coming from a payer questionnaire rather than an intake form, render payer questionnaire version of success page
  const payerQuestionnaireResponseIdMatch = useMatch(
    '/payer-questionnaire/:id/submit-success'
  );
  const payerQuestionnaireResponseId =
    payerQuestionnaireResponseIdMatch?.params.id;
  const { data: payerQuestionnaireData } = usePayerQuestionnaire({
    payerQuestionnaireResponseId,
    incompleteOnly: false,
  });

  return (
    <div className="relative h-screen w-screen bg-hue-lightYellow">
      {payerQuestionnaireData && (
        <div css={postIntakeInfoCollectionCSS}>
          <div className="piic-header">
            <span className="piic-heading">
              Apply to {payerQuestionnaireData?.frontEndCarrier.name}
            </span>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <div className="mt-[100px] flex w-[500px] flex-col gap-4 p-4 tablet:p-0">
          <div className="mb-4 flex flex-col">
            <BrandText variant="section">
              Headway will get started on your application
            </BrandText>
          </div>
          {!isOptumManagedMedicaid && (
            <>
              <BodyText>
                <strong>
                  We'll work with our insurance partners to get you approved as
                  fast as we can.
                </strong>
              </BodyText>
              <BodyText>
                You’ll be able to view your application status at any time from
                your provider portal, and we’ll send you an email as soon as
                you’re approved. We’re excited for you to get started!
              </BodyText>
            </>
          )}

          {isOptumManagedMedicaid ? (
            <OptumManagedMedicaidCopy />
          ) : isMedicareAdvantage ? (
            <MedicareAdvantageNextSteps />
          ) : (
            <>
              <LinkButton component={Link} to="/home" elementType="a">
                Go to provider portal
              </LinkButton>

              <div className="flex flex-row border-b-0 border-l-0 border-r-0 border-t-[0.4px] border-solid border-system-black pt-2 ">
                <HumanTouchpoint
                  imageSources={[Mehek, Katie]}
                  primaryLabel="Credentialing Team"
                  secondaryLabel="at Headway"
                />
              </div>
            </>
          )}
        </div>
        <div className="absolute bottom-0 right-0 hidden phone:hidden tablet:hidden desktop:block">
          <img src={blueArt} className="h-[338px] w-[283px] object-fill" />
        </div>
      </div>
    </div>
  );
};

export function SubmissionSuccess() {
  const variant = useFlag(PROVIDER_REFERRAL_PROGRAM_AWARENESS);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isMedicareAdvantage =
    queryParams.get(MEDICARE_ADVANTAGE_PARAM) === 'true';
  const isOptumManagedMedicaid =
    queryParams.get(OPTUM_MANAGED_MEDICAID_PARAM) === 'true';

  const payerQuestionnaireResponseIdMatch = useMatch(
    '/payer-questionnaire/:id/submit-success'
  );
  const payerQuestionnaireResponseId =
    payerQuestionnaireResponseIdMatch?.params.id;
  const { data: payerQuestionnaireData } = usePayerQuestionnaire({
    payerQuestionnaireResponseId,
    incompleteOnly: false,
  });
  const { user } = useAuthStore();

  if (!variant || variant === 'Control') {
    return <SubmissionSuccessControl />;
  }

  return (
    <div className="relative min-h-screen w-screen bg-system-white p-5">
      {payerQuestionnaireData && (
        <div css={postIntakeInfoCollectionCSS}>
          <div className="piic-header">
            <span className="piic-heading">
              Apply to {payerQuestionnaireData?.frontEndCarrier.name}
            </span>
          </div>
        </div>
      )}
      <div className="flex justify-center">
        <div className="mt-[100px] flex max-w-[950px] flex-col items-start gap-5 p-4 tablet:p-0">
          <div className="mb-2 flex flex-col">
            <BrandText variant="section">Great work!</BrandText>
            <BrandText variant="section">We've got it from here.</BrandText>
          </div>
          <BodyText>
            We'll work with our insurance partners to get you approved as fast
            as we can. You’ll be able to view your application status at any
            time from your provider portal, and we’ll send you an email as soon
            as you’re approved. We’re excited for you to get started!
          </BodyText>
          {isMedicareAdvantage ? (
            <MedicareAdvantageNextSteps />
          ) : (
            <>
              <LinkButton component={Link} to="/home" elementType="a">
                Go to provider portal
              </LinkButton>
            </>
          )}
          {user.referral_link_code && (
            <ProviderReferralProgramPromotionalCard variant={variant} />
          )}
        </div>
      </div>
    </div>
  );
}

const content = {
  INCENTIVE: {
    title: 'Earn $350 while you wait',
    description: (
      <BodyText>
        Share Headway with your network. For every provider that joins through
        your referral link, you get $350 and they get $100. Terms apply.{' '}
        <HelixLink component={Link} to="/refer-a-provider" elementType="a">
          Learn about our referral program
        </HelixLink>
      </BodyText>
    ),
    asset: incentiveBrandAsset,
  },
  MISSION: {
    title: 'Help more providers accept insurance',
    description: (
      <BodyText>
        Share Headway with your network and make mental healthcare more
        accessible. For every provider that joins through your referral link,
        you get $350 and they get $100. Terms apply.{' '}
        <HelixLink component={Link} to="/refer-a-provider" elementType="a">
          Learn more about our referral program
        </HelixLink>
      </BodyText>
    ),
    asset: missionBrandAsset,
  },
};

interface ProviderReferralProgramPromotionalCardProps {
  variant: 'Control' | 'INCENTIVE' | 'MISSION';
}

function ProviderReferralProgramPromotionalCard({
  variant,
}: ProviderReferralProgramPromotionalCardProps) {
  const authStore = useAuthStore();

  const query = useQuery(['referral-portal', authStore.user?.id], async () => {
    return await UserApi.getReferralPortal(authStore.user.id);
  });

  const clipboard = useClipboard({
    copiedTimeout: 1000,
    onSuccess() {
      toasts.add('Copied!', {
        variant: 'positive',
      });
    },
    onError() {
      toasts.add('Unable to copy to clipboard', {
        variant: 'negative',
      });
    },
  });

  if (!variant || variant === 'Control') {
    return null;
  }

  return (
    <div className="flex flex-col-reverse overflow-hidden rounded-[6px] tablet:flex-row">
      <div className=" flex flex-col gap-4 bg-system-backgroundGray p-5">
        <div>
          <div className="flex items-center gap-2">
            {variant === 'INCENTIVE' && <PaymentsIcon />}
            <ListHeader>{content[variant].title}</ListHeader>
          </div>
          <p className="mt-3">{content[variant].description}</p>
        </div>
        <form className="hidden tablet:block">
          <Group>
            <TextField
              name="referralLink"
              readonly
              placeholder={query.data ? undefined : 'Loading...'}
              value={query.data?.shareUrl ?? ''}
              label="Share your referral link"
              helpText="We’ll reach out to anyone who signs up using your link."
            />
            <Button
              disabled={!query.data?.shareUrl}
              variant="secondary"
              onPress={() => {
                clipboard.copy(query.data?.shareUrl);
              }}
              // This is how LaunchDarkly tracks the button click as a conversion event
              // for the experiment.
              data-ld-target="copy-referral-link"
            >
              Copy link
            </Button>
          </Group>
        </form>
        <form className="grid gap-2 tablet:hidden">
          <TextField
            name="referralLink"
            readonly
            placeholder={query.data ? undefined : 'Loading...'}
            value={query.data?.shareUrl ?? ''}
            label="Share your referral link"
            helpText="We’ll reach out to anyone who signs up using your link."
          />
          <Button
            disabled={!query.data?.shareUrl}
            variant="secondary"
            onPress={() => {
              clipboard.copy(query.data?.shareUrl);
            }}
            // This is how LaunchDarkly tracks the button click as a conversion event
            // for the experiment.
            data-ld-target="copy-referral-link"
          >
            Copy link
          </Button>
        </form>
      </div>
      <img
        src={content[variant].asset}
        className="w-full object-cover tablet:w-[36.8%]"
      />
    </div>
  );
}
