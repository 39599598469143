import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

import { UserApi } from '@headway/api/resources/UserApi';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { BrandText } from '@headway/helix/BrandText';
import { Button } from '@headway/helix/Button';
import { CollapsibleDisclosure } from '@headway/helix/CollapsibleDisclosure';
import { Divider } from '@headway/helix/Divider';
import { GlossaryTerm } from '@headway/helix/GlossaryTerm';
import { Group } from '@headway/helix/Group';
import { Link } from '@headway/helix/Link';
import { PageHeader } from '@headway/helix/PageHeader';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { TextField } from '@headway/helix/TextField';
import { theme } from '@headway/helix/theme';
import { toasts } from '@headway/helix/Toast';
import {
  AddPerson,
  Document,
  EventAvailable,
  WavingHand,
} from '@headway/icons';
import referralImg from '@headway/shared/assets/img/product/referral.jpeg';
import { REFERRAL_BONUS_AMOUNT } from '@headway/shared/constants/referrals';
import { useQuery } from '@headway/shared/react-query';

import {
  ExtoleDataResponse,
  ExtoleUserResponse,
  fetchExtoleUserData,
} from '../hooks/extole';

/*************** THESE ARE JUST PLACEHOLDERS *******************/
const LINKEDIN_CONNECTION_HREF =
  'https://www.linkedin.com/search/results/people/?geoUrn=%5B%22103644278%22%5D&network=%5B%22F%22%2C%22S%22%2C%22O%22%5D&origin=FACETED_SEARCH&sid=tP9&titleFreeText=therapist%20OR%20psychologist%20OR%20psychiatrist%20OR%20psychiatric%20NP';
const WHO_TO_REFER_LINK = 'https://www.example.com';
const ACCEPTED_LICENSE_LINK = 'https://www.example.com';
const handleDirectReferal = () => {};
/***************************************************************/

const ReferralProgramHeader = ({
  ReferralAccountComponent: ReferralAccountComponent,
  imgComponent: ImgComponent = 'img',
  shareableLink,
  extoleAccessToken,
}: {
  AuthStore: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
  ReferralAccountComponent: React.FC<React.PropsWithChildren<any>>;
  shareableLink?: string;
  extoleAccessToken?: string;
}) => {
  const [referralLinkButtonText, setReferralLinkButtonText] =
    useState('Copy link');

  const clipboard = useClipboard({
    copiedTimeout: 1000,
    onSuccess() {
      toasts.add('Copied!', {
        variant: 'positive',
      });
    },
    onError() {
      toasts.add('Unable to copy to clipboard', {
        variant: 'negative',
      });
    },
  });

  return (
    <div className="header w-full ">
      <div className="grid w-full grid-cols-[2fr_1fr] justify-between gap-12">
        <div>
          <div>
            <div className="hlx-typography-list-heading">
              Refer a provider: Earn ${REFERRAL_BONUS_AMOUNT}
            </div>
            <BrandText>Invite a provider to join a rewarding network</BrandText>
            <br />
            <div className="hlx-typography-list-heading flex flex-col gap-1 pb-4 pt-4">
              <span>
                Invite a provider to join the largest network of therapists and
                psychiatrists that accept insurance in the country.
              </span>
              <Link
                href={LINKEDIN_CONNECTION_HREF}
                target="_blank"
                rel="noopener"
              >
                Find relevant LinkedIn connections↗
              </Link>
            </div>
          </div>
          {shareableLink ? (
            <>
              <div className="flex flex-col space-y-2 pt-4">
                <Group>
                  <TextField
                    name="referralLink"
                    defaultValue={shareableLink}
                    label="Share your referral link (via social media)"
                    readonly
                  />
                  <Button
                    type="button"
                    variant="secondary"
                    size="large"
                    disabled={!shareableLink}
                    onPress={() => {
                      clipboard.copy(shareableLink);
                      setReferralLinkButtonText('Copied');
                    }}
                    // This is how LaunchDarkly tracks the button click as a conversion event
                    // for the experiment.
                    data-ld-target="copy-referral-link"
                  >
                    {referralLinkButtonText}
                  </Button>
                </Group>
              </div>
            </>
          ) : (
            <ReferralAccountComponent />
          )}
        </div>
        <div className="min-w-452 min-h-495 flex w-full items-center justify-center overflow-hidden">
          <ImgComponent
            // in Patient the sigmundPicture import is StaticImageData which
            // can't be assigned to an img src. All usage in Patient is expected
            // to pass an imgComponent that knows how to handle StaticImageData.
            // @ts-ignore
            src={referralImg}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

const howItWorksItems = [
  {
    icon: AddPerson,
    primary: 'Refer a provider',
    secondary: (
      <>
        Refer licensed mental health providers by inviting them directly or by
        sharing your unique referral link on social media.{' '}
        <Link href={WHO_TO_REFER_LINK} target="_blank" rel="noopener">
          Learn more about who to refer.
        </Link>
      </>
    ),
  },
  {
    icon: WavingHand,
    primary: 'Intro meeting',
    secondary:
      "We'll reach out to your referral to schedule an introduction meeting.",
  },
  {
    icon: Document,
    primary: 'Credentialing',
    secondary: `You'll receive $${REFERRAL_BONUS_AMOUNT} after your referral submits their credentialing intake form.`,
    badge: `Get $${REFERRAL_BONUS_AMOUNT}`,
  },
  {
    icon: EventAvailable,
    primary: 'First session',
    secondary:
      'Your referral will earn a $100 bonus after they confirm their first session on Headway.',
    badge: 'Give $100',
  },
];

const ReferralHowItWorks = () => {
  return (
    <div className="my-12 w-full">
      <PageHeader>How it works</PageHeader>
      {howItWorksItems.map((item, index) => {
        return (
          <div key={index} className="my-4 w-full">
            <div className="flex flex-row space-x-3">
              <div className="bg-system-lightGray flex h-10 w-10 items-center justify-center rounded-full p-4">
                <item.icon
                  className="shrink-0"
                  width="20"
                  height="20"
                  fill="black"
                />
              </div>
              <div className="flex flex-col space-y-1">
                <div className="flex flex-row gap-1">
                  <BodyText>{item.primary}</BodyText>
                  {item.badge && <Badge variant="positive">{item.badge}</Badge>}
                </div>
                <SubBodyText color="gray">{item.secondary}</SubBodyText>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

// const insuranceCoverages = [
//   {
//     imgSrc: Aetna,
//     alt: 'Aetna',
//   },
//   {
//     imgSrc: '',
//     alt: 'United Healthcare',
//   },
//   {
//     imgSrc: '',
//     alt: 'BlueCross BlueShield',
//   },
//   {
//     imgSrc: '',
//     alt: 'Cigna',
//   },
//   {
//     imgSrc: '',
//     alt: 'Anthem'
//   },
//   {
//     imgSrc: '',
//     alt: 'Humana'
//   }
// ];

// const InsuranceCoverage = () => {
//   return (
//     <>
//       <Divider />
//       <div
//         className="flex w-full flex-row items-center justify-between"
//         css={{
//           ...theme.typography.list,
//           fontHeight: theme.typography.sectionHeader.lineHeight,
//           fontWeight: theme.fontWeight.regular,
//         }}
//       >
//         We accept most major insurances
//         {insuranceCoverages.map((coverage) => {
//           return (
//             <div className="flex">
//               <ImgComponent src={coverage.imgSrc} alt={coverage.alt} />
//             </div>
//           );
//         })}
//       </div>
//       <Divider />
//     </>
//   );
// };

const WhoToRefer = () => {
  const listOfLicenses = [
    'Nurse Practitioner (NP)',
    'Doctor of Osteopathic Medicine (DO)',
    'Licensed Clinical Social Worker (LCSW)',
    'Licensed Marriage/Family Therapist (LMFT)',
    'Medical Doctor (MD)',
    'Clinical Psychologist (CP)',
    'Licensed Psychologist (LP)',
  ];
  return (
    <div className="my-12 w-full space-y-6">
      <div className="flex flex-col space-y-5">
        <PageHeader>Who to refer</PageHeader>
        <div
          css={{
            fontSize: theme.typography.list.fontSize,
            lineHeight: theme.typography.sectionHeader.lineHeight,
            fontWeight: theme.fontWeight.regular,
          }}
        >
          Refer licensed mental health providers who want to start working with
          insurance. Common licenses we accept are:
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center border p-4">
        {listOfLicenses.map((license, index) => {
          return (
            <div
              key={index}
              className="flex-shrink-0 p-4"
              css={{
                ...theme.typography.brand.section,
                color: theme.color.system.green,
              }}
            >
              {license}
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
        <Button
          type="button"
          variant="secondary"
          size="large"
          onPress={() => {}}
        >
          See a comprehensive list of accepted licenses by state ↗
        </Button>
      </div>
    </div>
  );
};

const YourReferrals = ({
  userReferralDetails,
}: {
  userReferralDetails?: ExtoleUserResponse;
}) => {
  let pendingRedemption = 0;
  let totalEarned = 0;
  let totalSignups = 0;

  if (userReferralDetails) {
    userReferralDetails.rewards.forEach((reward) => {
      if (reward.state === 'pending') {
        pendingRedemption += Number(reward.faceValue || 0);
      } else {
        totalEarned += Number(reward.faceValue || 0);
      }
    });

    totalSignups = userReferralDetails.friends.filter(
      (friend) => friend.status === 'Signed Up'
    ).length;
  }

  const ReferralStats = [
    {
      title: 'Total referrals',
      value: `${userReferralDetails?.friends.length || 0}`,
    },
    {
      title: <GlossaryTerm term="Sign ups" />,
      value: `${totalSignups}`,
    },
    {
      title: 'Earned',
      value: `${totalEarned}`,
      note: (
        <>
          ${pendingRedemption} <GlossaryTerm term="Pending redemption" />
        </>
      ),
    },
  ];

  return (
    <div className="my-12 w-full space-y-6">
      <Divider autoSpacing={true} />
      <h2>
        <PageHeader>Your referrals</PageHeader>
      </h2>
      <div className="flex space-x-4">
        {ReferralStats.map((stat, index) => {
          return (
            <div className="flex-1 p-4" key={index}>
              <BodyText>{stat.title}</BodyText>
              <div
                css={{
                  fontSize: '38px',
                  lineHeight: '50.54px',
                  fontWeight: '500',
                }}
              >
                {stat.value}
              </div>
              {stat.note && <BodyText color="gray">{stat.note}</BodyText>}
            </div>
          );
        })}
      </div>
      <Divider autoSpacing={true} />
    </div>
  );
};

const referralFaqs = [
  {
    question: 'How do I refer?',
    answer: (
      <>
        <div className="flex flex-col gap-1">
          <p>There are 2 ways to refer:</p>
          <ol className="list-inside list-decimal pl-0">
            <li>
              <strong>Invite a provider directly</strong>
              <p className="pl-4">
                If you know a provider who is ready to join, invite a them
                directly by submitting their contact information, and our team
                will reach out to them directly to schedule an intro meeting.
              </p>
            </li>
            <li>
              <strong>Share your unique referral link</strong>
              <p className="pl-4">
                Share your referral link with a provider or with an audience on
                social media, and providers can sign up at their own pace.
              </p>
            </li>
          </ol>
        </div>
      </>
    ),
  },
  {
    question: 'Who should I refer?',
    answer: (
      <>
        <div className="flex flex-col gap-1">
          <p>
            Refer licensed mental health providers. We work with Master's Level
            Therapists, Clinical Psychologists, and Psychiatric Care
            Practitioners
          </p>
          <p>
            <Link href={ACCEPTED_LICENSE_LINK} target="_blank" rel="noopener">
              View a comprehensive list of accepted licenses by state.
            </Link>
          </p>
        </div>
      </>
    ),
  },
  {
    question: 'What is considered a successful referral?',
    answer:
      'You’ll qualify for a reward when the provider you refer submits our credentialing intake form to start the insurance paneling process.',
  },
  {
    question: 'How and when will I receive my reward?',
    answer:
      'Once you qualify for a reward, you will receive a link in your email to redeem your reward through Paypal within [X days].',
  },
  {
    question: 'Why did my referral get rejected?',
    answer: (
      <>
        <div className="flex flex-col gap-1">
          <p>
            Referrals may be rejected for a variety of reasons. They may be
            rejected:
          </p>
          <ol className="ml-4 list-decimal pl-0">
            <li>
              If they don’t have the necessary professional credentials: They
              must meet the insurer requirements to get credentialed. These
              requirements include licensure, board certifications, and
              prescriber registrations.
            </li>
            <li>Your referral has already joined Headway as a provider.</li>
            <li>Your referral was referred by someone else before you.</li>
          </ol>
        </div>
      </>
    ),
  },
  {
    question: 'What happened to my old referral link?',
    answer:
      'We introduced new links in order to make it possible to track your referral statuses, but your old referral links are still active! You’ll be able to track statuses and earn rewards for referrals submitted through those old links. ',
  },
];

const ReferralFaqs = () => {
  return (
    <div>
      <PageHeader>Frequently asked questions</PageHeader>
      <ul className="my-6 flex flex-col justify-start pl-0">
        {referralFaqs.map((faq, index) => {
          return (
            <div key={index}>
              <CollapsibleDisclosure size="large" label={faq.question}>
                <div className="pt-3">
                  <BodyText>{faq.answer}</BodyText>
                </div>
              </CollapsibleDisclosure>
              <Divider />
            </div>
          );
        })}
      </ul>
    </div>
  );
};
interface ReferProviderProps {
  AuthStore?: any;
  imgComponent?: React.FC<React.PropsWithChildren<any>> | 'img';
  ReferralAccountComponent: React.FC<React.PropsWithChildren<any>>;
}

export const ReferralProgram = ({
  AuthStore,
  imgComponent: ImgComponent = 'img',
  ReferralAccountComponent,
}: ReferProviderProps) => {
  const user = AuthStore.user;
  const referralPortalQuery = useQuery(['referralPortal', user.id], () =>
    UserApi.getReferralPortal(user.id)
  );
  const extoleAccessToken = referralPortalQuery.data?.accessToken;

  const query = useQuery(
    ['userReferralData', extoleAccessToken],
    async () => {
      const response = await fetchExtoleUserData(extoleAccessToken!);
      return response?.data;
    },
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 hours
      enabled: Boolean(extoleAccessToken),
      refetchOnWindowFocus: false,
    }
  );

  let userData;
  if (!userData && query.isSuccess) {
    userData = query.data;
  }

  return (
    <React.Fragment>
      <div className="mx-auto max-w-[1200px] px-6 pt-12">
        <ReferralProgramHeader
          AuthStore={AuthStore}
          imgComponent={ImgComponent}
          ReferralAccountComponent={ReferralAccountComponent}
          shareableLink={userData?.me?.shareable_link}
          extoleAccessToken={extoleAccessToken}
        />
        {/* {!userData || (!userData.me && <InsuranceCoverage />)} */}
        <ReferralHowItWorks />
        {userData?.me ? (
          <>
            <YourReferrals userReferralDetails={userData.me} />
            <ReferralFaqs />
          </>
        ) : (
          <WhoToRefer />
        )}
      </div>
    </React.Fragment>
  );
};
