import { FrontEndCarrierRead } from '@headway/api/models/FrontEndCarrierRead';
import { PanelabilityStatus } from '@headway/api/models/PanelabilityStatus';
import { PayerStatePanelabilityEvaluation } from '@headway/api/models/PayerStatePanelabilityEvaluation';
import { ProviderPanelabilityEvaluation } from '@headway/api/models/ProviderPanelabilityEvaluation';

export type ConsolidatedProviderPanelabilityEvaluation = {
  panelingInfoForPayers: PanelingInfoForPayer[];
};

export type PanelingInfoForPayer = {
  carrier_id: number;
  displayName: string;
  status: PanelabilityStatus;
  messages: string[];
};

const PANELABILITY_STATUS_RANKING_MAP: { [key in PanelabilityStatus]: number } =
  {
    [PanelabilityStatus.NOT_PANELABLE]: 0,
    [PanelabilityStatus.MAYBE_PANELABLE]: 1,
    [PanelabilityStatus.PANELABLE]: 2,
  };

export const consolidateProviderPanelabilityEvaluation = ({
  panelabilityEvaluation,
  carriersById,
}: {
  panelabilityEvaluation: ProviderPanelabilityEvaluation;
  carriersById: { [index: string]: FrontEndCarrierRead };
}): ConsolidatedProviderPanelabilityEvaluation => {
  const stateCarrierEvaluationByPayer =
    panelabilityEvaluation.stateInsuranceCarrierPanelabilityEvaluations?.reduce(
      (
        result: {
          [key: number]: PayerStatePanelabilityEvaluation[];
        },
        currentValue: PayerStatePanelabilityEvaluation
      ) => {
        result[currentValue.frontEndCarrierId] =
          result[currentValue.frontEndCarrierId] || [];
        result[currentValue.frontEndCarrierId].push(currentValue);
        return result;
      },
      {} as {
        [key: number]: PayerStatePanelabilityEvaluation[];
      }
    );
  return {
    panelingInfoForPayers: Object.entries(
      stateCarrierEvaluationByPayer
    ).flatMap(([carrierId, stateInsuranceEvaluationList]) => {
      const carrierIdNum = parseInt(carrierId);
      const itemsToReturn = [];
      const vnnCarriers = stateInsuranceEvaluationList.filter(
        (evaluation) =>
          !!evaluation.ruleEvaluations &&
          !!evaluation.ruleEvaluations['panelable_for_wrap_network']
      );
      const nonVnnCarriers = stateInsuranceEvaluationList.filter(
        (evaluation) =>
          !evaluation.ruleEvaluations ||
          !evaluation.ruleEvaluations['panelable_for_wrap_network']
      );
      if (vnnCarriers?.length) {
        const vnnCarrier = getLeastRestrictiveEvaluation(vnnCarriers);
        itemsToReturn.push({
          carrier_id: carrierIdNum,
          status: vnnCarrier.finalPanelabilityStatus,
          displayName: `${carriersById[carrierIdNum].name} (Virtual network)`,
          messages: getMessagesFromStateInsuranceEvaluation(vnnCarrier),
        });
      }
      if (nonVnnCarriers?.length) {
        // We expect all the statuses across states to be the same, and we don't have designs to
        // reflect a different status for each state, so we just pick the least restrictive status.
        // In the backend before this response is returned, we will log if we see any instances of
        // a carrier having different evaluations across states

        const leastRestrictiveEvaluation =
          getLeastRestrictiveEvaluation(nonVnnCarriers);

        itemsToReturn.push({
          carrier_id: carrierIdNum,
          status: leastRestrictiveEvaluation.finalPanelabilityStatus,
          displayName: carriersById[carrierIdNum].name,
          messages: getMessagesFromStateInsuranceEvaluation(
            leastRestrictiveEvaluation
          ),
        });
      }
      return itemsToReturn;
    }),
  };
};

function getMessagesFromStateInsuranceEvaluation(
  stateInsuranceEvaluation: PayerStatePanelabilityEvaluation
): string[] {
  if (!stateInsuranceEvaluation || !stateInsuranceEvaluation?.ruleEvaluations) {
    return [];
  }
  const allRuleEvaluations = Object.values(
    stateInsuranceEvaluation.ruleEvaluations
  )
    .map((groupRuleEvaluation) => groupRuleEvaluation.ruleEvaluations ?? [])
    .flat();
  return allRuleEvaluations
    .filter(
      (ruleEvaluation) =>
        !!ruleEvaluation.status &&
        ruleEvaluation.status ==
          stateInsuranceEvaluation.finalPanelabilityStatus
    )
    .map((ruleEvaluation) => ruleEvaluation.panelingMessages ?? [])
    .flat();
}

function getLeastRestrictiveEvaluation(
  stateInsuranceEvaluations: PayerStatePanelabilityEvaluation[]
): PayerStatePanelabilityEvaluation {
  return stateInsuranceEvaluations.reduce((prev, current) => {
    if (
      PANELABILITY_STATUS_RANKING_MAP[current.finalPanelabilityStatus] <
      PANELABILITY_STATUS_RANKING_MAP[prev.finalPanelabilityStatus]
    ) {
      return current;
    }
    return prev;
  }, stateInsuranceEvaluations[0]);
}
