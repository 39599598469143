import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { BillingType } from '@headway/api/models/BillingType';
import { EligibilityLookupRead } from '@headway/api/models/EligibilityLookupRead';
import { PatientInsuranceOrEAPStatus } from '@headway/api/models/PatientInsuranceOrEAPStatus';
import { ProviderPatientRead } from '@headway/api/models/ProviderPatientRead';
import { ProviderPatientUpdate } from '@headway/api/models/ProviderPatientUpdate';
import { ProviderRead } from '@headway/api/models/ProviderRead';
import { UserInsuranceRead } from '@headway/api/models/UserInsuranceRead';
import { UserInviteChannel } from '@headway/api/models/UserInviteChannel';
import { UserRead } from '@headway/api/models/UserRead';
import { ProviderPatientApi } from '@headway/api/resources/ProviderPatientApi';
import { Button } from '@headway/helix/Button';
import { GuidanceCard } from '@headway/helix/GuidanceCard';
import { ENABLE_NO_TELEHEALTH_EXPERIENCE } from '@headway/shared/FeatureFlags/flagNames';
import { USE_BENEFITS_MAX_FOR_CLAIM_READINESS } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/flags';
import { useFrontEndCarriers } from '@headway/shared/hooks/useFrontEndCarriers';
import { formatPatientName } from '@headway/shared/utils/patient';
import { logException } from '@headway/shared/utils/sentry';
import {
  FieldCheckbox,
  FieldControl,
  FieldErrorText,
  Form,
} from '@headway/ui/form';
import { Loader } from '@headway/ui/Loader';
import { ProviderFrontEndCarrierContext } from '@headway/ui/providers/ProviderFrontEndCarrierProvider';
import { theme } from '@headway/ui/theme';
import { notifyError } from '@headway/ui/utils/notify';

import { useInsuranceStatus } from '../../hooks/useInsuranceStatus';
import {
  billingTypeFormIntialValues,
  PatientBillingTypeFormFields,
  patientBillingTypeFormValidationSchema,
  PatientBillingTypeFormValues,
} from './PatientBillingTypeForm';
import { PatientBillingUnconfirmedWarning } from './PatientBillingUnconfirmedWarning';
import {
  insuranceFormInitialValues,
  insuranceFormValidationSchema,
  InsuranceFormValues,
  PatientInsuranceFormFields,
  PatientInsuranceFormFieldsProps,
  patientInsuranceFormSubmit,
} from './PatientInsuranceForm';
import {
  SelfPayFormFieldsProps,
  selfPayFormInitialValues,
  selfPayFormValidationSchema,
  SelfPayFormValues,
  SelfPayRateFormFields,
} from './SelfPayRateForm';
import { useProviderEventsUnconfirmedQuery } from './utils/customQueries';
import { hasNoRemainingSessions } from './utils/hasNoRemainingSessions';
import { useIsSelfPayEligibleEvenIfInNetwork } from './utils/useIsSelfPayEligibleEvenIfInNetwork';

type PatientBillingFormProps = PatientInsuranceFormFieldsProps &
  SelfPayFormFieldsProps & {
    onUpdateSuccess: (update: PatientBillingFormUpdate) => Promise<void> | void;
    provider: ProviderRead;
    patient: UserRead;
    providerPatient: ProviderPatientRead;
    showPatientAddedAlert?: boolean;
    startFormOnSelfPay: boolean;
    openSelfPayProviderOptInModal: () => void;
  };

type PatientBillingFormValues = InsuranceFormValues &
  SelfPayFormValues &
  PatientBillingTypeFormValues & {
    confirmComms: boolean;
    confirmNotInNetwork: boolean;
  };

export type PatientBillingFormUpdate = {
  userRead: UserRead;
  providerPatient: ProviderPatientRead;
  userInsurance?: UserInsuranceRead;
  eligibilityLookup?: EligibilityLookupRead;
};

/**
 * Billing form for patient that allow to select either Insurance or Self-Pay based forms.
 *
 * Composes the following:
 *  PatientInsuranceForm
 *  PatientSelfPayForm
 *  PatientBillingTypeForm
 */

export const PatientBillingForm = ({
  patient,
  provider,
  providerPatient,
  onUpdateSuccess,
  onCancel,
  showAuthorizationInstructionsModal,
  showPatientAddedAlert,
  openSelfPayProviderOptInModal,
  startFormOnSelfPay,
}: PatientBillingFormProps) => {
  const {
    frontEndCarriers: allFrontEndCarriers,
    carriersById: frontEndCarriersById,
  } = useFrontEndCarriers();
  const providerFrontEndCarrierContext = React.useContext(
    ProviderFrontEndCarrierContext
  );

  const patientName = formatPatientName(patient, {
    firstNameOnly: true,
  });

  const { insuranceStatus } = useInsuranceStatus(
    patient,
    patient.activeUserInsurance
  );

  const isSelfPayEligibleEvenIfInNetwork =
    useIsSelfPayEligibleEvenIfInNetwork(patient);

  let selfPayEligible: boolean = false;

  if (insuranceStatus === PatientInsuranceOrEAPStatus.NO_INSURANCE) {
    selfPayEligible =
      patient.inviteChannel === UserInviteChannel.PROVIDER_PORTAL;
  } else {
    selfPayEligible =
      isSelfPayEligibleEvenIfInNetwork ||
      insuranceStatus !== PatientInsuranceOrEAPStatus.IN_NETWORK;
  }

  const noTelehealthExperienceEnabled = useFlag(
    ENABLE_NO_TELEHEALTH_EXPERIENCE,
    false
  );

  const benefitsMaxEnabled = useFlag(
    USE_BENEFITS_MAX_FOR_CLAIM_READINESS,
    false
  );

  selfPayEligible =
    selfPayEligible ||
    !!patient.activeUserInsurance?.latestEligibilityLookup?.outOfNetwork ||
    (!!patient.activeUserInsurance?.latestEligibilityLookup
      ?.hasNoTelehealthBenefits &&
      noTelehealthExperienceEnabled) ||
    hasNoRemainingSessions(patient, benefitsMaxEnabled) ||
    providerPatient.billingTypeDefault === BillingType.SELF_PAY;

  let selfPayEnabled = false;
  if (!selfPayEligible) {
    selfPayEnabled = false;
  } else {
    selfPayEnabled = !!provider.selfPayTermsAcceptanceDate;
  }

  let startingBillingType;
  if (!selfPayEligible) {
    startingBillingType = BillingType.INSURANCE;
  } else if (startFormOnSelfPay) {
    startingBillingType = BillingType.SELF_PAY;
  } else {
    startingBillingType = providerPatient.billingTypeDefault;
  }

  const { isLoading, providerEventsUnconfirmed, error } =
    useProviderEventsUnconfirmedQuery(providerPatient);

  const validationSchema = Yup.lazy(
    (value: Yup.Shape<object | undefined, PatientBillingFormValues>): any => {
      return Yup.object().shape({
        ...patientBillingTypeFormValidationSchema.fields,
        ...(value?.billingTypeDefault === BillingType.INSURANCE &&
          insuranceFormValidationSchema.fields),
        ...(value?.billingTypeDefault === BillingType.SELF_PAY && {
          ...selfPayFormValidationSchema.fields,
          confirmComms: Yup.boolean().oneOf(
            [true],
            'You must acknowledge that Headway will send communications to this client.'
          ),
          confirmNotInNetwork: Yup.boolean().oneOf(
            [true],
            'You must confirm that the client is either not in-network or not interested in in-network care before continuing.'
          ),
        }),
      });
    }
  );

  if (isLoading) return <Loader flex={true} />;

  const unconfirmedCount = providerEventsUnconfirmed
    ? providerEventsUnconfirmed.totalCount
    : 0;
  const shouldShowAlert = error || unconfirmedCount > 0;

  return (
    <Formik<PatientBillingFormValues>
      initialValues={{
        ...billingTypeFormIntialValues(startingBillingType),
        ...insuranceFormInitialValues(patient),
        ...selfPayFormInitialValues(providerPatient.selfPayRateDefault),
        confirmComms: !!providerPatient.providerSelfPayAttestationDate,
        confirmNotInNetwork: !!providerPatient.providerSelfPayAttestationDate,
      }}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={async (values: PatientBillingFormValues) => {
        if (values.billingTypeDefault === BillingType.SELF_PAY) {
          try {
            const payload: ProviderPatientUpdate = {
              billingTypeDefault: values.billingTypeDefault,
              selfPayRateDefault: values.selfPayRateDefault,
            };

            if (!providerPatient.providerSelfPayAttestationDate) {
              payload.providerSelfPayAttestationDate = new Date().toISOString();
            }

            const newProviderPatient =
              await ProviderPatientApi.updateProviderPatient(
                providerPatient.id,
                payload
              );
            onUpdateSuccess({
              userRead: patient,
              providerPatient: newProviderPatient,
            });
          } catch (err) {
            notifyError(
              `There was a problem saving ${formatPatientName(patient, {
                firstNameOnly: true,
              })}'s billing information`
            );
            logException(err);
          }
        } else {
          let newProviderPatient = providerPatient;
          if (
            providerPatient.billingTypeDefault !== values.billingTypeDefault
          ) {
            // Billing type changed
            newProviderPatient = await ProviderPatientApi.updateProviderPatient(
              providerPatient.id,
              {
                billingTypeDefault: values.billingTypeDefault,
              }
            );
          }
          await patientInsuranceFormSubmit(
            values,
            patient,
            frontEndCarriersById,
            ({ userRead, userInsurance, eligibilityLookup }) =>
              onUpdateSuccess({
                userRead,
                userInsurance,
                eligibilityLookup,
                providerPatient: newProviderPatient,
              })
          );
        }
      }}
    >
      {({ isSubmitting, values, ...props }) => {
        return (
          <>
            {shouldShowAlert && (
              <PatientBillingUnconfirmedWarning
                error={error}
                unconfirmedCount={unconfirmedCount}
              />
            )}
            {showPatientAddedAlert && (
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.space.base,
                  marginBottom: theme.space.base,
                }}
              >
                {values.billingTypeDefault === BillingType.INSURANCE ||
                !selfPayEligible ? (
                  !patient?.activeUserInsuranceId ? (
                    <GuidanceCard variant="info">
                      {patientName} has been added to your practice! You can add
                      their insurance details below, or your client can add them
                      on their end.
                    </GuidanceCard>
                  ) : null
                ) : (
                  <GuidanceCard variant="info">
                    {patientName} has been added to your practice! You can edit
                    the private pay rate to customize this client’s rate if
                    needed.
                  </GuidanceCard>
                )}
              </div>
            )}
            <Form>
              {selfPayEligible && <PatientBillingTypeFormFields descriptive />}
              {values.billingTypeDefault === BillingType.INSURANCE ||
              !selfPayEligible ? (
                <PatientInsuranceFormFields
                  patient={patient}
                  allFrontEndCarriers={allFrontEndCarriers}
                  providerFrontEndCarrierContext={
                    providerFrontEndCarrierContext
                  }
                  showAuthorizationInstructionsModal={
                    showAuthorizationInstructionsModal
                  }
                  values={values}
                  isSubmitting={isSubmitting}
                  onCancel={onCancel}
                  cancelBtnText={
                    showPatientAddedAlert ? 'Skip for now' : 'Cancel'
                  }
                  {...props}
                />
              ) : (
                <>
                  {selfPayEnabled ? (
                    <>
                      <SelfPayRateFormFields
                        isSubmitting={isSubmitting}
                        onCancel={onCancel}
                        cancelBtnText={
                          showPatientAddedAlert ? 'Skip for now' : 'Cancel'
                        }
                        {...props}
                      >
                        <>
                          <FieldControl name="confirmComms">
                            <FieldCheckbox
                              fieldType="boolean"
                              value={true}
                              label="I acknowledge that this client will receive communications from Headway."
                            />
                            <FieldErrorText />
                          </FieldControl>
                          <FieldControl name="confirmNotInNetwork">
                            <FieldCheckbox
                              fieldType="boolean"
                              value={true}
                              label="I attest that to the best of my knowledge, this client is not covered by an insurance with which Headway is in-network"
                            />
                            <FieldErrorText
                              sx={{ bottom: `-${theme.space.xl3}` }}
                            />
                          </FieldControl>
                        </>
                      </SelfPayRateFormFields>
                    </>
                  ) : (
                    <div>
                      <h6>Enable Private Pay</h6>
                      <p>
                        Bring your private pay clients to Headway and keep your
                        practice in one place. Simply set your rate and schedule
                        sessions, and we’ll include these payments with your
                        existing Headway payouts.
                        {/*<a href="TODO_INSERT_ZENDESK_LINK">Learn More.</a>*/}
                      </p>
                      <div css={{ textAlign: 'right' }}>
                        <Button
                          variant="primary"
                          onPress={() => {
                            openSelfPayProviderOptInModal();
                          }}
                        >
                          Enable Private Pay
                        </Button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
