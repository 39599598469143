import { css } from '@emotion/react';
import { Skeleton } from '@mui/material';
import { useProvider, useProviderPatient } from 'hooks';
import React from 'react';

import { AuthorizationType } from '@headway/api/models/AuthorizationType';
import { BillingType } from '@headway/api/models/BillingType';
import { Ethnicity } from '@headway/api/models/Ethnicity';
import { FrontEndCarrierIdentifier } from '@headway/api/models/FrontEndCarrierIdentifier';
import { Language } from '@headway/api/models/Language';
import { PatientAddressRead } from '@headway/api/models/PatientAddressRead';
import { UnitedStates } from '@headway/api/models/UnitedStates';
import { Badge } from '@headway/helix/Badge';
import { BodyText } from '@headway/helix/BodyText';
import { Button } from '@headway/helix/Button';
import { IconError } from '@headway/helix/icons/Error';
import { SubBodyText } from '@headway/helix/SubBodyText';
import { theme } from '@headway/helix/theme';
import {
  ETHNICITY_DISPLAY_NAMES,
  GENDER_DISPLAY_NAMES,
  GENDER_STATUS_DISPLAY_NAMES,
  LANGUAGE_DISPLAY_NAMES,
} from '@headway/shared/constants/socialDeterminantsOfHealthDisplayNames';
import statesToDisplayNames from '@headway/shared/constants/unitedStatesDisplayNames';
import {
  ACCEPT_ANTHEM_BANK_OF_AMERICA_EAP,
  SIGMUND_EMERGENCY_CONTACTS,
} from '@headway/shared/FeatureFlags/flagNames';
import { MULTI_STATE_CREDENTIALING_BETA } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import { useEmergencyContacts } from '@headway/shared/hooks/useEmergencyContact';
import { useShouldShowAnthemEAPExperience } from '@headway/shared/hooks/useShouldShowAnthemEAPExperience';
import { useUser } from '@headway/shared/hooks/useUser';
import { formatPatientName } from '@headway/shared/utils/patient';
import { maskPhoneNumber } from '@headway/shared/utils/strings';
import { VisuallyHidden } from '@headway/ui/VisuallyHidden';

import { useActivePatientAddress } from 'hooks/useActivePatientAddress';
import { useInsuranceAuthorizations } from 'hooks/useInsuranceAuthorizations';
import { useInsuranceStatus } from 'hooks/useInsuranceStatus';
import { useMSCGuardrail } from 'hooks/useMSCGuardrail';
import { PatientInsuranceStatusLabel } from 'views/Patients/PatientInsuranceStatusLabel';

export interface ClientInfoBoxProps {
  clientId: number;
  onOpenAddressModal: () => void;
  onOpenEmergencyContactModal: () => void;
}

const Term = ({ children }: { children: React.ReactNode }) => (
  <dt>
    <BodyText>
      <b>{children}</b>
    </BodyText>
  </dt>
);

const Definition = ({ children }: { children: React.ReactNode }) => (
  <dd css={definitionCss}>{children}</dd>
);

const definitionCss = css({
  margin: 0,
});

/** Info box used on the client details page. */
export const ClientInfoBox = ({
  clientId,
  onOpenAddressModal,
  onOpenEmergencyContactModal,
}: ClientInfoBoxProps) => {
  const { data: client } = useUser({ userId: clientId });
  const provider = useProvider();
  const { data: providerPatient } = useProviderPatient({
    providerId: provider.id,
    patientId: clientId,
  });
  const billingType = providerPatient?.billingTypeDefault;
  const insurance = client?.activeUserInsurance;
  const { insuranceStatus, isLoading: insuranceStatusIsLoading } =
    useInsuranceStatus(client, insurance);
  const { data: address, isLoading: addressIsLoading } =
    useActivePatientAddress({ patientUser: client });
  const { data: emergencyContacts } = useEmergencyContacts(clientId);
  const sigmundEmergencyContacts = useFlag(SIGMUND_EMERGENCY_CONTACTS, false);
  const isMSCEnabled = useFlag(MULTI_STATE_CREDENTIALING_BETA, false);
  const { isMSCGuardrailWarning, isMSCGuardrailRestriction } =
    useMSCGuardrail();
  const {
    insuranceStatus: insuranceStatusCheckState,
    isLoading: insuranceStatusCheckStateIsLoading,
  } = useInsuranceStatus(
    client,
    insurance,
    undefined,
    undefined,
    isMSCEnabled || isMSCGuardrailWarning || isMSCGuardrailRestriction
  );

  const showAnthemEAPExperience = useShouldShowAnthemEAPExperience(
    clientId,
    providerPatient?.id,
    undefined
  );

  return (
    <section css={containerCss} data-testid="clientInfoBox">
      <dl css={definitionListCss}>
        <Term>Email</Term>
        <Definition>
          {client ? (
            <span css={emailAndInsuranceCss}>
              <BodyText>{client.email || '—'}</BodyText>
              {client.isVerified ? (
                <Badge variant="positive">Verified</Badge>
              ) : (
                <Badge variant="negative" icon={IconError}>
                  Unverified
                </Badge>
              )}
            </span>
          ) : (
            <Skeleton width="300px" />
          )}
        </Definition>
        <Term>Phone</Term>
        <Definition>
          {client ? (
            <BodyText>
              {client.phone ? maskPhoneNumber(client.phone) : '—'}
            </BodyText>
          ) : (
            <Skeleton width="300px" />
          )}
        </Definition>
        <Term>Insurance</Term>
        <Definition>
          {billingType ? (
            billingType === BillingType.SELF_PAY ? (
              <Badge variant="warning">Out of pocket</Badge>
            ) : (
              <span css={emailAndInsuranceCss}>
                {insurance?.billingFrontEndCarrierName && (
                  <BodyText>{insurance.billingFrontEndCarrierName}</BodyText>
                )}
                {!insuranceStatusIsLoading &&
                  !showAnthemEAPExperience.isLoading &&
                  (showAnthemEAPExperience.data ? (
                    <BodyText>{'—'}</BodyText>
                  ) : (
                    <PatientInsuranceStatusLabel
                      providerId={provider.id}
                      insuranceStatus={insuranceStatus}
                      insuranceStatusCheckState={insuranceStatusCheckState}
                      patientUser={client}
                    />
                  ))}
              </span>
            )
          ) : (
            <Skeleton width="300px" />
          )}
        </Definition>
        <Term>Client ID</Term>
        <Definition>
          <BodyText>{clientId}</BodyText>
        </Definition>
        {!!client?.activePatientAddressId && !isMSCEnabled && (
          <>
            <Term>Address</Term>
            <Definition>
              {addressIsLoading ? (
                <>
                  <Skeleton width="300px" />
                  <Skeleton width="300px" />
                </>
              ) : (
                <>
                  {address ? <AddressDisplay address={address} /> : '—'}
                  <div>
                    <Button
                      size="large"
                      variant="link"
                      onPress={() => onOpenAddressModal()}
                    >
                      Change
                      <VisuallyHidden> address</VisuallyHidden>
                    </Button>
                  </div>
                </>
              )}
            </Definition>
          </>
        )}
        {client && client.gender && (
          <>
            <Term>Gender</Term>
            <Definition>
              <BodyText>{GENDER_DISPLAY_NAMES[client.gender]}</BodyText>
            </Definition>
          </>
        )}
        {client && client.genderStatus && (
          <>
            <Term>Gender status</Term>
            <Definition>
              <BodyText>
                {GENDER_STATUS_DISPLAY_NAMES[client.genderStatus]}
              </BodyText>
            </Definition>
          </>
        )}
        {client && client.ethnicity && (
          <>
            <Term>Ethnicity</Term>
            <Definition>
              <BodyText>
                {client.ethnicity
                  .map(
                    (ethnicity: Ethnicity) => ETHNICITY_DISPLAY_NAMES[ethnicity]
                  )
                  .join(', ')}
              </BodyText>
            </Definition>
          </>
        )}
        {client && client.languages && (
          <>
            <Term>Language</Term>
            <Definition>
              <BodyText>
                {client.languages
                  .map((language: Language) => LANGUAGE_DISPLAY_NAMES[language])
                  .join(', ')}
              </BodyText>
            </Definition>
          </>
        )}
        {sigmundEmergencyContacts && <Term>Emergency contacts</Term>}
        {sigmundEmergencyContacts && (
          <Definition>
            <Button
              size="large"
              variant="link"
              onPress={() => onOpenEmergencyContactModal()}
            >
              <BodyText>
                {emergencyContacts?.length === 0
                  ? 'Add contacts'
                  : 'View contacts'}
              </BodyText>
            </Button>
          </Definition>
        )}
      </dl>
      <SubBodyText color="gray">
        {client?.isVerified ? (
          `${formatPatientName(client, {
            firstNameOnly: true,
          })}'s account is already verified, so further changes can only be made on their end.`
        ) : !!client ? (
          "Make sure this information matches that of the person being seen, even if they're a minor."
        ) : (
          <Skeleton width="300px" />
        )}
      </SubBodyText>
    </section>
  );
};

const containerCss = css({
  padding: `${theme.spacing.x5} ${theme.spacing.x4}`,
  borderRadius: '6px',
  border: `1px solid ${theme.color.system.borderGray}`,
  boxSizing: 'border-box',
});

const definitionListCss = css({
  display: 'grid',
  gridTemplateColumns: '148px auto',
  gap: theme.spacing.x3,
  marginTop: 0,
  marginBottom: theme.spacing.x5,
  overflowX: 'auto',
});

const emailAndInsuranceCss = css({
  display: 'flex',
  gap: theme.spacing.x2,
});

interface AddressDisplayProps {
  address: PatientAddressRead;
}
function AddressDisplay({ address }: AddressDisplayProps) {
  return (
    <div>
      <BodyText>{address.streetLine1}</BodyText>
      {address.streetLine2 && (
        <>
          <br />
          <BodyText>{address.streetLine2}</BodyText>
        </>
      )}
      <br />
      <BodyText>
        {address.city && address.state ? (
          <span>
            {address.city},{' '}
            {statesToDisplayNames[address.state as UnitedStates]}
          </span>
        ) : address.city ? (
          <span>{address.city}</span>
        ) : address.state ? (
          <span>{statesToDisplayNames[address.state as UnitedStates]}</span>
        ) : null}{' '}
        {address.zipCode}
      </BodyText>
    </div>
  );
}
