import { AxiosError, AxiosResponse, Method } from 'axios';
import React from 'react';

import { axios as axiosClient } from '@headway/api/axios.config';
import { EXTOLE_REFERRAL_PROGRAM } from '@headway/shared/FeatureFlags/flagNames';
import { useFlag } from '@headway/shared/FeatureFlags/react';
import useScript from '@headway/shared/utils/useScript';

const EXTOLE_DOMAIN = 'https://headway.extole.io';

interface ExtoleUserData {
  email?: string;
  first_name?: string;
  last_name?: string;
  /**
   * The user's unique identifier in our system.  This is how Extole will relate their
   * user back to Headway's user.
   */
  partner_user_id: string;
}

function useExtole() {
  const isExtoleEnabled = useFlag(EXTOLE_REFERRAL_PROGRAM, false);

  const scriptStatus = useScript(
    isExtoleEnabled ? `${EXTOLE_DOMAIN}/core.js` : '',
    {
      async: true,
      type: 'text/javascript',
    }
  );

  // @ts-expect-error
  return scriptStatus === 'ready' ? window.extole : null;
}

interface ExtoleOptions {
  name: string;
  element: HTMLElement;
  jwt?: string;
  data:
    | {
        container?: 'test';
      }
    | (ExtoleUserData & { container?: 'test' });
}
/**
 * Returns a callback that can be used to track a lead form conversion in Extole.
 */
function useExtoleLeadFormConversion(): (data: ExtoleOptions['data']) => void {
  const extole = useExtole();

  return React.useCallback(
    (data) => {
      if (!extole) {
        return;
      }

      if (
        (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
        'production'
      ) {
        data.container = 'test';
      }
      extole.createZone({
        name: 'lead_form_submitted',
        data: data,
      });
    },
    [extole]
  );
}

/**
 * Returns a ref that can be attached to an element that you'd like to embed an Extole zone in.
 * If a token is provided, it will be used to authenticate the user with Extole.
 */
function useExtoleEmbed(
  token: string | undefined
): React.RefObject<HTMLDivElement> {
  const extoleZoneRef = React.useRef<HTMLDivElement | null>(null);

  const extole = useExtole();

  React.useEffect(() => {
    if (!extole || !extoleZoneRef.current) {
      return;
    }

    (function (c, b, f, k, a) {
      // @ts-expect-error
      c[b] = c[b] || {};
      // @ts-expect-error
      for (c[b].q = c[b].q || []; a < k.length; ) f(k[a++], c[b]);
    })(
      window,
      'extole',
      // @ts-expect-error
      function (c, b) {
        b[c] =
          b[c] ||
          function () {
            b.q.push([c, arguments]);
          };
      },
      ['createZone'],
      0
    );

    const opts: ExtoleOptions = {
      name: 'embedded_microsite',
      element: extoleZoneRef.current,
      jwt: token,
      data: {},
    };

    if (
      (process.env.REACT_APP_ENVIRONMENT ?? process.env.ENVIRONMENT) !==
      'production'
    ) {
      opts.data.container = 'test';
    }

    extole.createZone(opts);
  }, [extole]);

  return extoleZoneRef;
}

export { useExtoleEmbed, useExtoleLeadFormConversion, fetchExtoleUserData };

export interface ExtoleUserResponse extends ExtoleUserData {
  profile_picture_url?: string;
  shareable_link: string;
  advocate_code: string;
  rewards: [
    {
      rewardId: string;
      partnerRewardId: string;
      faceValue: string;
      faceValueType: string;
      dateEarned: string;
      rewardType: string;
      partnerRewardSupplierId: string;
      state: string;
    },
  ];
  friends: [
    {
      firstNameOrEmail: string;
      initials: string;
      email: string;
      profilePictureUrl: string;
      status: string;
    },
  ];
}

export interface ExtoleDataResponse {
  program_label: string;
  campaign_id: string;
  links: {
    company_url: string;
    terms_url: string;
    how_it_works_url: string;
  };
  sharing: {
    email: {
      subject: string;
      message: string;
    };
    native: {
      message: string;
    };
    sms: {
      message: string;
    };
  };
  calls_to_action: {
    account_page: {
      message: string;
    };
    confirmation: {
      message: string;
    };
    menu: {
      message: string;
    };
    product: {
      message: string;
    };
  };
  me: ExtoleUserResponse;
}
interface ExtoleResponse {
  event_id: string;
  campaign_id: string;
  data: ExtoleDataResponse;
}

const fetchExtoleUserData = async (token: string): Promise<ExtoleResponse> => {
  const reqConfig = {
    method: 'POST' as Method,
    url: `${EXTOLE_DOMAIN}/api/v6/zones`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: { event_name: 'advocate_mobile_experience' },
  };

  try {
    const response = await axiosClient.request(reqConfig);
    return response.data as ExtoleResponse;
  } catch (error) {
    throw error;
  }
};
